import React from 'react';
import { Box, Grid, Typography, Button } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import * as amplitude from '@amplitude/analytics-browser';

const ShareCard = () => {
    const shareGame = () => {
        amplitude.track('Share Game From Card');
    }
    return (
        <Box
            borderRadius="10px"
            sx={{
                m: 2,
                p: 2,
                alignItems: 'center',
                bgcolor: 'quaternary.main',
                opacity: 0.8,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="body1" align='center'>
                        Share for your&nbsp;
                        <Typography component="span" variant="body1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            next pre-game
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<ShareIcon />}
                            onClick={shareGame}
                            sx={{
                                borderRadius: '10px',
                                fontWeight: 'bold',
                                color: 'secondary.main',
                                height: '60px',
                                fontSize: 16,
                                display: 'flex',
                                alignItems: 'center',
                                minWidth: '250px',
                            }}
                        >
                            Share
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ShareCard;
