import React, { useContext } from 'react';
import { Box, Typography } from "@mui/material";
import { GameContext } from '../GameContext';
import { useLocation, useParams } from 'react-router-dom';

const PromptContainer = () => {
    const { currentCardDescription } = useContext(GameContext);
    const location = useLocation();
    const { urlGameCode } = useParams();
    const isCreativeMode = location.pathname.startsWith("/creative-mode/");
    const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
    const storedCurrentCardType = storedLocalData && storedLocalData.currentCardType;
    const storedCurrentPrompt = storedLocalData && storedLocalData.currentPrompt;
    const storedCurrentPlayerNames = storedLocalData && storedLocalData.currentPlayerNames;

    let playerNameText;
    if (storedCurrentPlayerNames && storedCurrentPlayerNames.length === 1) {
      playerNameText = storedCurrentPlayerNames[0];  
    } else if (storedCurrentPlayerNames && storedCurrentPlayerNames.length === 2) {
      playerNameText = `${storedCurrentPlayerNames[0]} & ${storedCurrentPlayerNames[1]}`;
    } else {
        console.log('no player names');
    }

    return (
        <Box>
            {isCreativeMode && <Typography variant="h5" color='primary' fontWeight={'bold'} textAlign='center' mb={2} >
               {playerNameText}
            </Typography>}
            {storedCurrentCardType === 'guess' && isCreativeMode &&
                <Typography variant="h5" color='black' textAlign='center' mb={2}>
                    Who do you think wrote the prompt below?
                </Typography>
            }
            <Typography variant="h5" color='black' textAlign='center' mb={2}>
                { isCreativeMode ? `Drink if you think ${storedCurrentPrompt}` : currentCardDescription }
            </Typography>
        </Box>
    );
};

export default PromptContainer;


// ToDo
    // need to add contional logic to show names, guess prompt and style the prompt, hide/show like/dislike button
    // need to account for errors when playing simple mode with adding 'setCurrentCardDescription' and other code for creative mode being called when not valid
