import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

const Podium = () => {
  return (
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent="center"
      width={'100%'}
    >
      <Paper
        elevation={1}
        sx={{
          height: 85,
          width: '32%',
          textAlign: 'center',
          backgroundColor: 'primary.main',
          color: 'secondary.main',
          borderRadius: '20px 0 0 0',
        }}
      >
        <Typography variant="h4" pt={1}>2</Typography>
      </Paper>
      <Paper
        elevation={5}
        sx={{
          height: 120,
          width: '36%',
          textAlign: 'center',
          marginBottom: 0,
          backgroundColor: 'primary.main',
          color: 'secondary.main',
          borderRadius: '20px 20px 0 0',
        }}
      >
        <Typography variant="h4" pt={1}>1</Typography>
      </Paper>
      <Paper
        elevation={1}
        sx={{
          height: 60,
          width: '32%',
          textAlign: 'center',
          marginBottom: 0,
          backgroundColor: 'primary.main',
          color: 'secondary.main',
          borderRadius: '0 20px 0 0',
        }}
      >
        <Typography variant="h4" pt={1}>3</Typography>
      </Paper>
    </Box>
  );
};

export default Podium;
