import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material';
import ContentCard from '../components/game_container/ContentCard';
import NavButtons from '../components/game_container/NavButtons';
import UpdateScore from '../components/game_container/UpdateScore';
import { useParams } from 'react-router-dom';

function GamePlayPage() {
    const [currentCardType, setCurrentCardType] = useState(null);
    const { urlGameCode } = useParams();

    useEffect(() => {
        const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
        if (storedLocalData) { 
            setCurrentCardType(storedLocalData.currentCardType); // may need to put outside if statement
        } else {
            console.log('No local data found');
        }
    }, [urlGameCode, setCurrentCardType]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Required for Chrome
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <Box>
            <ContentCard />
            {currentCardType === 'prompt' &&
                <NavButtons
                    setCurrentCardType={setCurrentCardType}
                />
            }
            {currentCardType === 'guess' &&
                <UpdateScore
                    setCurrentCardType={setCurrentCardType}
                />
            }
        </Box>
    )
}

export default GamePlayPage