import React, { useState } from 'react';
import { Box, Typography, Stack } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import CustomAutocomplete from './CustomAutocomplete';
import ExplicitToggle from './ExplicitToggle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomTooltip from '../info/CustomTooltip';

const CustomizeGameCard = () => {
    const [expanded, setExpanded] = useState(true);

    const handleCollapse = () => {
        setExpanded(!expanded);
    };

    return (
        <Box bgcolor="quaternary.main" borderRadius="10px" sx={{ m: 2, p: 2 }}>
            <Stack
                fontSize={"1.5rem"}
                direction="row"
                alignItems="center"
                justifyContent="center"
                onClick={handleCollapse}
                height='100'
            >
                <Typography component="span" variant="h6">
                    Make the content
                    <Typography component="span" fontWeight="bold" variant="1.5rem" color="primary.main">&nbsp;fit your group</Typography>
                </Typography>
                {!expanded &&
                        <ExpandMoreIcon anchor="right" sx={{ ml: 1 }} onClick={handleCollapse} />
                }
            </Stack>
            {expanded && (
                <>
                    <br />
                    <CustomAutocomplete />
                    <CustomTooltip
                        title="We use your group's description to generate tailored prompts to spark the best discussions"
                        duration={6000}
                    >
                        <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography fontSize="12px">What does this mean?</Typography>
                            <HelpIcon fontSize='small' />
                        </Stack>
                    </CustomTooltip>
                    <br />
                    <br />
                    <ExplicitToggle />
                    <CustomTooltip
                        title="Optional setting to include rated R equivalent content"
                        duration={4000}
                    >
                        <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography fontSize="12px">What does this mean?</Typography>
                            <HelpIcon fontSize='small' />
                        </Stack>
                    </CustomTooltip>
                </>
            )}
            {expanded && (
                <Stack direction="column" spacing={0} justifyContent="center" alignContent='center' sx={{ marginTop: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <ExpandLessIcon onClick={handleCollapse} />
                    </Box>
                </Stack>
            )}
        </Box>
    );
};

export default CustomizeGameCard;
