import React, { useContext } from 'react';
import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import CustomTooltip from '../info/CustomTooltip';
import PlayerStatus from './PlayerStatus';
import { useNavigate, useParams } from 'react-router-dom';
import { GameContext } from '../GameContext';
import ShareCodeButton from '../share/ShareCodeButton';
import { getReadyPlayers, addReadyPlayersToDB } from '../utils/dbUtils';
import { selectPrompt, getThinkersNames, getOrderOfNames, getOrderOfPrompts } from '../utils/gameUtils';
import * as amplitude from '@amplitude/analytics-browser';
import QRCodeButton from '../share/QRCodeButton';
import CropFreeIcon from '@mui/icons-material/CropFree';

const WaitingLobbyCard = () => {

    const {
        gameCode,
        players,
        setCurrentCardNumber,
    } = useContext(GameContext);
    const navigate = useNavigate();
    const { urlGameCode } = useParams();

    const readyPlayersCount = players.filter(player => player.isReady).length;
    const isStartButtonEnabled = readyPlayersCount >= 4;

    // WaitingLobbyCard.js

    const handleStartClick = async () => {
        amplitude.track('Start Creative Mode');

        if (isStartButtonEnabled) {
            const readyPlayers = await getReadyPlayers(urlGameCode);
            await addReadyPlayersToDB(urlGameCode, readyPlayers);
            const orderOfNamesObj = getOrderOfNames(readyPlayers);
            const orderOfPrompts = getOrderOfPrompts(
                readyPlayers,
                orderOfNamesObj,
            );
            const data = {
                ...JSON.parse(localStorage.getItem(urlGameCode + '_data')),
                currentCardNumber: 1,
                currentPlayerIndex: 0,
                currentGuesserIndex: 0,
                orderOfNames: orderOfNamesObj,
                orderOfPrompts: orderOfPrompts,
                readyPlayers: readyPlayers,
                currentCardType: 'prompt',
                scoreHistory: [],
            };
            localStorage.setItem(urlGameCode + '_data', JSON.stringify(data));

            // must keep in seperate udpate since the methods depend on the previous data being saved locally first
            const updateData = {
                ...JSON.parse(localStorage.getItem(urlGameCode + '_data')),
                currentPlayerNames: getThinkersNames(urlGameCode),
                currentPrompt: selectPrompt(urlGameCode),
            }
            localStorage.setItem(urlGameCode + '_data', JSON.stringify(updateData));
            
            setCurrentCardNumber(1); // not sure if this context variable is needed any more in creative mode; maybe needed in simple mode

            navigate(`/creative-mode/${gameCode}/play`);
        }

    }

    return (
        <Box borderRadius="10px" sx={{ m: 2, p: 2, bgcolor: 'quaternary.main', opacity: 0.8 }}>
            <Grid container spacing={2} alignItems="center" sx={{ opacity: 1 }}>
                <Grid item xs={12} mb={4}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid container xs={8} >
                            <Typography variant="body1">
                                Need atleast 4 players
                            </Typography>
                            <CustomTooltip
                                title="Once at least four players have joined and submitted prompts, the start button will be clickable."
                            >
                                <HelpIcon fontSize='small' sx={{ pl: 1 }} />
                            </CustomTooltip>
                        </Grid>
                        <Grid container item xs={4} direction="column" alignItems="center" justifyContent="center">
                            <Stack direction="row">
                                <ShareCodeButton
                                    code={urlGameCode}
                                    iconProps={{ color: 'primary' }}
                                />
                                <QRCodeButton
                                    code={urlGameCode}
                                    buttonIcon={<CropFreeIcon sx={{ color: 'primary', minWidth: 0 }} />}
                                />
                            </Stack>
                            <Typography variant="body2" color='black'>
                                {urlGameCode}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <PlayerStatus />
                <Grid item xs={12} mt={4}>
                    <Stack direction="column" spacing={2}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="tertiary"
                                onClick={handleStartClick}
                                disabled={!isStartButtonEnabled}
                                sx={{
                                    borderRadius: '30px',
                                    fontWeight: 'bold',
                                    color: 'secondary.main',
                                    height: '60px',
                                    fontSize: '24px',
                                }}
                            >
                                Start
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {!isStartButtonEnabled && (
                                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                    You need at least 4 players ready to start
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default WaitingLobbyCard;
