import React, { useState } from "react";
import { AppBar, Drawer, Toolbar, Typography, Box } from "@mui/material";
import CustomizeGameCard from "./CustomizeGameCard";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import RuleCard from "./RuleCard";
import { ReactComponent as JRLogo } from "../media/js_logo_60px.svg";
import LegalFooter from "./LegalFooter";
import FormCard from '../feedback_card/FormCard';
import ShareButton from '../share/ShareButton';
import ShareCard from "../share/ShareCard";
import HomeButton from "./HomeButton";
import { useLocation } from "react-router-dom";

const Header = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const location = useLocation();

    const isSimpleModeGamePage = location.pathname.includes("/simple-mode/play");

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <AppBar position="fixed">
            <Toolbar>
                <div style={{ display: 'grid', gridTemplateColumns: '.1fr 2fr .1fr .1fr', alignItems: 'center', width: '100%' }}>
                    <JRLogo />
                    <Typography variant="h6" color="secondary.main" p={1}>
                        Drink If You Think
                    </Typography>
                    <ShareButton />
                    <MenuIcon onClick={toggleDrawer} sx={{ color: "secondary.main" }} />
                </div>
            </Toolbar>
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
                <Toolbar sx={{ bgcolor: 'primary.main', position: 'sticky', top: 0, zIndex: 1 }}>
                    <Box sx={{ display: 'grid', gridTemplateColumns: '.1fr 2fr .1fr', alignItems: 'center', width: '100%' }}>
                        <JRLogo />
                        <Typography variant="h6" color="secondary.main" p={1}>
                            Drink If You Think
                        </Typography>
                        <CloseIcon onClick={toggleDrawer} sx={{ color: "secondary.main" }} />
                    </Box>
                </Toolbar>
                <Box>
                    <HomeButton toggleDrawer={toggleDrawer} />
                    {isSimpleModeGamePage && <CustomizeGameCard />}
                    <RuleCard />
                    <FormCard />
                    <ShareCard />
                    <br />
                    <LegalFooter />
                </Box>
            </Drawer>
        </AppBar>
    );
}

export default Header;