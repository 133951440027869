import React, { useState } from 'react';
import { Button, IconButton, Modal, Stack, Typography } from "@mui/material";
import QRCode from 'qrcode.react';

const QRCodeButton = ({ code, buttonIcon }) => {
    const [showQR, setShowQR] = useState(false);

    const codePath = `/creative-mode/${code}`
    const enterPromptsURL = `${window.location.origin}${codePath}/enter-prompts`;

    return (
        <>
            <IconButton 
            onClick={() => setShowQR(!showQR)}
            color='primary'
            >
                {buttonIcon}
            </IconButton>
            <Modal
                open={showQR}
                onClose={() => setShowQR(false)}
            >
                <Stack
                    sx={{
                        alignItems: 'center',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        py: 2,
                        px: 4,
                        bgcolor: 'quaternary.main',
                        borderRadius: '10px',
                    }}
                >
                    <Typography variant="body1" align='center' mb={2} color={'primary.main'} fontWeight={'bold'}>
                        Let others scan to submit their own prompts
                    </Typography>
                    <QRCode value={enterPromptsURL} size={200} />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowQR(!showQR)}
                        sx={{
                            borderRadius: '10px',
                            fontWeight: 'bold',
                            color: 'secondary.main',
                            mt: 2,
                        }}
                    >
                        Close
                    </Button>
                </Stack>
            </Modal>
        </>
    );
};


export default QRCodeButton;