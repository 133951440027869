import React from 'react';
import GameModeCard from '../components/pre_game/GameModeCard.js';
import ResultsCard from '../components/game_container/results/ResultsCard';
import ShareCard from '../components/share/ShareCard.js';
import FormCard from '../components/feedback_card/FormCard.js';
import { Box } from '@mui/material';

function ResultsPage() {
    return (
        <Box>
            <ResultsCard />
            <GameModeCard />
            <ShareCard />
            <FormCard />
        </Box>
    )
}

export default ResultsPage
