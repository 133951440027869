import React, { useContext } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Box from '@mui/material/Box';
import { GameContext } from '../GameContext';

const LikeDislikeButton = () => {
  const { 
    updateUserProfile, 
    userProfile, 
    currentCardIndex,
    setLikeClicked,
    likeClicked,
    setDislikeClicked,
    dislikeClicked
  } = useContext(GameContext);
  // const [likeClicked, setLikeClicked] = useState(false);
  // const [dislikeClicked, setDislikeClicked] = useState(false);

// Todo: check this works with more than one card & fix the logic to reflect if the user unliked or undisliked a card and selects no like or dislike after.
const handleLikeClick = () => {
  setLikeClicked(!likeClicked);
  setDislikeClicked(false);
  
  let updatedLikedCards = userProfile.likedCards;
  let updatedDislikedCards = userProfile.dislikedCards.filter((index) => index !== currentCardIndex);

  if (!updatedLikedCards.includes(currentCardIndex)) {
    updatedLikedCards = [...updatedLikedCards, currentCardIndex];
  }
  
  const updatedProfile = { likedCards: updatedLikedCards, dislikedCards: updatedDislikedCards };
  updateUserProfile(updatedProfile);
};

const handleDislikeClick = () => {
  setDislikeClicked(!dislikeClicked);
  setLikeClicked(false);

  let updatedDislikedCards = userProfile.dislikedCards;
  let updatedLikedCards = userProfile.likedCards.filter((index) => index !== currentCardIndex);

  if (!updatedDislikedCards.includes(currentCardIndex)) {
    updatedDislikedCards = [...updatedDislikedCards, currentCardIndex];
  }

  const updatedProfile = { likedCards: updatedLikedCards, dislikedCards: updatedDislikedCards };
  updateUserProfile(updatedProfile);
};

  

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <ThumbUpIcon
        onClick={handleLikeClick}
        color={likeClicked ? 'primary' : 'secondary'}
      />
      <ThumbDownIcon
        onClick={handleDislikeClick}
        color={dislikeClicked ? 'primary' : 'secondary'}
      />
    </Box>
  );
};

export default LikeDislikeButton;
