// homepage
import React, { useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import ContentCard from './game_container/ContentCard';
import NavButtons from './game_container/NavButtons';
import { GameContext } from './GameContext';
import FormCard from './feedback_card/FormCard';
import data from '../data2.js';
import UpdateDatabase from './UpdateDatabase';
import GameModeCard from './pre_game/GameModeCard';
import ShareCard from './share/ShareCard';
const Homepage = () => {

  const {
    userProfile,
    updateUserProfile,
    isLastCardShowing,
  } = useContext(GameContext);


  // empty playedCards array from userProfile 
  useEffect(() => {
    let allAvailableCards = data;
    if (!userProfile.includeExplicitContent) {
      allAvailableCards = allAvailableCards.filter((item) => !item.explicit);
    }
    const allAvailableCardIds = allAvailableCards.map(card => card.id);
    const allPlayed = allAvailableCardIds.every(id => userProfile.playedCards.includes(id));
    if (allPlayed) {
      console.log('all cards played');
      updateUserProfile({ playedCards: [] });
      updateUserProfile({ hasPlayedAllCards: true });
    }
  }, [userProfile, updateUserProfile]);

  return (
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box mt={6} mb={12}>
          <ContentCard />
          {
            isLastCardShowing && (
              <>
                <GameModeCard />
                <FormCard />
                <ShareCard />
              </>
            )
          }
        </Box>
      <NavButtons />
      <UpdateDatabase />
    </Box>
  );

};

export default Homepage;