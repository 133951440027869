import React from 'react'
import ShareCodeCard from '../components/pre_game/ShareCodeCard'
import PlayerStatus from '../components/pre_game/PlayerStatus'
import { Box } from '@mui/material';


function ShareCodePage() {
    return (
        <Box>
            <ShareCodeCard />
            <PlayerStatus />
        </Box>
    )
}

export default ShareCodePage
