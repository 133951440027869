///*global REACT_APP_*/ 
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyD1Mv7IL1XbsIz5NnWzbRYjIDyDJLHmvPE",
    authDomain: "just-recess.firebaseapp.com",
    projectId: "just-recess",
    storageBucket: "just-recess.appspot.com",
    messagingSenderId: "1091140354235",
    appId: "1:1091140354235:web:e3556c00d1f40cbdb6e99e"
  };
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

// REACT_APP_FIREBASE_KEY = "AIzaSyD1Mv7IL1XbsIz5NnWzbRYjIDyDJLHmvPE",
// REACT_APP_FIREBASE_DOMAIN = "just-recess.firebaseapp.com",
// REACT_APP_FIREBASE_PROJECT_ID = "just-recess",
// REACT_APP_FIREBASE_STORAGE_BUCKET = "just-recess.appspot.com",
// REACT_APP_FIREBASE_SENDER_ID = "1091140354235",
// REACT_APP_FIREBASE_APP_ID =  "1:1091140354235:web:e3556c00d1f40cbdb6e99e"

// const firebaseConfig = {
//     apiKey: "AIzaSyD1Mv7IL1XbsIz5NnWzbRYjIDyDJLHmvPE",
//     authDomain: "just-recess.firebaseapp.com",
//     projectId: "just-recess",
//     storageBucket: "just-recess.appspot.com",
//     messagingSenderId: "1091140354235",
//     appId: "1:1091140354235:web:e3556c00d1f40cbdb6e99e"
//   };

// const firebaseConfig = {
//     apiKey: REACT_APP_FIREBASE_KEY,
//     authDomain: REACT_APP_FIREBASE_DOMAIN,
//     projectId: REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: REACT_APP_FIREBASE_SENDER_ID,
//     appId: REACT_APP_FIREBASE_APP_ID
//   };
