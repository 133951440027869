import React from 'react';
import { Box, Stack, Typography, Grid, Divider } from "@mui/material";
import { useParams } from 'react-router-dom';
import ShareCodeButton from '../share/ShareCodeButton';
import QRCodeButton from '../share/QRCodeButton';
import CropFreeIcon from '@mui/icons-material/CropFree';


const ClosePhoneMessageCard = () => {
    const { urlGameCode } = useParams();

    return (
        <Box
            borderRadius="10px"
            sx={{
                m: 2,
                p: 2,
                alignItems: 'center',
                bgcolor: 'quaternary.main',
                opacity: 0.8,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Stack textAlign="center" spacing={2}>
                <Typography variant="h6">
                    Thanks for adding your prompts!
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    Put your phone away. You don’t need it anymore :)
                </Typography>
                <Typography variant="h6">
                    The host will take it from here.
                </Typography>
                <Divider variant='middle' />
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Stack direction="row">
                        <ShareCodeButton
                            code={urlGameCode}
                            iconProps={{ color: 'primary' }}
                        />
                        <QRCodeButton
                            code={urlGameCode}
                            buttonIcon={<CropFreeIcon sx={{ color: 'primary', minWidth: 0 }} />}
                        />
                    </Stack>
                    <Typography variant="body2" color='black'>
                        share code: {urlGameCode}
                    </Typography>
                </Grid>
            </Stack>
        </Box>
    );
};

export default ClosePhoneMessageCard;
