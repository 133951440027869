// gameUtils.js

export function getOrderOfNames(readyPlayers) {

  let numPrompts
  if (readyPlayers.length >= 6) {
    numPrompts = 2;
  } else {
    numPrompts = 3;
  }

  const desiredLength = numPrompts * readyPlayers.length;

  // Extract just the names
  const players = readyPlayers.map(p => p.name);

  // Repeat names to match length  
  const updatedPlayers = [];
  while (updatedPlayers.length < desiredLength) {
    const copy = [...players];
    updatedPlayers.push(...copy);
  }

  let guessers = shuffleArray(updatedPlayers);
  let thinkers = [];

  for (let i = 0; i < updatedPlayers.length; i++) {
    let partnerIndex = Math.floor(Math.random() * updatedPlayers.length);
    while (updatedPlayers[i] === updatedPlayers[partnerIndex]) {
      partnerIndex = Math.floor(Math.random() * updatedPlayers.length);
    }
    thinkers.push([updatedPlayers[i], updatedPlayers[partnerIndex]]);
  }

  while (hasMatchingNames(guessers, thinkers)) {
    guessers = shuffleArray(guessers);
  }

  return { guessers, thinkers };
}

function hasMatchingNames(guessers, thinkers) {
  for (let i = 0; i < guessers.length; i++) {
    if (guessers[i] === thinkers[i][0] || guessers[i] === thinkers[i][1]) {
      return true;
    }
  }
  return false;
}

function shuffleArray(array) {
  // Shuffle logic
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

export function getOrderOfPrompts(readyPlayers, orderOfNames) {
  let orderOfPrompts = [];

  const copiedPlayers = JSON.parse(JSON.stringify(readyPlayers));

  // deletes pompt 3 for players with 6+ players, but console log before and after copiedPlayers does not change need deep copy.
  if (copiedPlayers.length >= 6) {
    copiedPlayers.forEach(player => {
      delete player.unusedPrompts[2];
    });
  }

  for (let i = 0; i < orderOfNames.thinkers.length; i++) {

    const currentGuesser = orderOfNames.guessers[i];

    const validPlayers = copiedPlayers.filter(p => p.name !== currentGuesser);

    const validPrompts = validPlayers.flatMap(player => player.unusedPrompts);

    const randomIndex = Math.floor(Math.random() * validPrompts.length);

    const prompt = validPrompts[randomIndex];

    copiedPlayers.forEach(player => {
      if (player.unusedPrompts.includes(prompt)) {
        player.unusedPrompts = player.unusedPrompts.filter(p => p !== prompt);
        player.usedPrompts.push(prompt);
      }
    });


    orderOfPrompts.push(prompt);
  }

  return orderOfPrompts
}

export function selectPrompt(urlGameCode) {
  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
  const storedOrderOfPrompts = storedLocalData && storedLocalData.orderOfPrompts;
  const storedCurrentPlayerIndex = storedLocalData && storedLocalData.currentPlayerIndex;
  const prompt = storedOrderOfPrompts[storedCurrentPlayerIndex];

  return prompt;
}

export function selectPrevPrompt(urlGameCode) {
  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
  const storedOrderOfPrompts = storedLocalData && storedLocalData.orderOfPrompts;
  const storedCurrentPlayerIndex = storedLocalData && storedLocalData.currentPlayerIndex;
  const prompt = storedOrderOfPrompts[storedCurrentPlayerIndex - 1];

  return prompt;
}

export function getThinkersNames(urlGameCode) {
  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
  const storedOrderOfNames = storedLocalData && storedLocalData.orderOfNames;
  const storedCurrentPlayerIndex = storedLocalData && storedLocalData.currentPlayerIndex;
  const currentThinkers = storedOrderOfNames.thinkers[storedCurrentPlayerIndex];

  return currentThinkers;
}

export function getPrevThinkersNames(urlGameCode) {
  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
  const storedOrderOfNames = storedLocalData && storedLocalData.orderOfNames;
  const storedCurrentPlayerIndex = storedLocalData && storedLocalData.currentPlayerIndex;
  const currentThinkers = storedOrderOfNames.thinkers[storedCurrentPlayerIndex - 1];

  return currentThinkers;
}

export function getGuessersName(urlGameCode) {
  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
  const storedOrderOfNames = storedLocalData && storedLocalData.orderOfNames;
  const storedCurrentGuesserIndex = storedLocalData && storedLocalData.currentGuesserIndex;
  const currentGuesser = storedOrderOfNames.guessers[storedCurrentGuesserIndex];

  return currentGuesser;
}

export function getPrevGuessersName(urlGameCode) {
  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
  const storedOrderOfNames = storedLocalData && storedLocalData.orderOfNames;
  const storedCurrentGuesserIndex = storedLocalData && storedLocalData.currentGuesserIndex;
  const currentGuesser = storedOrderOfNames.guessers[storedCurrentGuesserIndex - 1];

  return currentGuesser;
}

export function updatePlayerScore(points, urlGameCode) {
  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
  let storedReadyPlayers = storedLocalData && storedLocalData.readyPlayers;

  const guessersName = getGuessersName(urlGameCode);
  console.log('guessersName: ', guessersName);

  const updatedGamePlayers = storedReadyPlayers.map(player => {
    return { ...player };
  });

  for (let i = 0; i < updatedGamePlayers.length; i++) {
    if (updatedGamePlayers[i].name === guessersName) {
      updatedGamePlayers[i].score += points;
      break;
    }
  }

  storedLocalData.readyPlayers = updatedGamePlayers;
  localStorage.setItem(urlGameCode + '_data', JSON.stringify(storedLocalData));

  addScoreHistory(urlGameCode, points);

}

function addScoreHistory(urlGameCode, points) {
  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
  let storedScoreHistory = storedLocalData && storedLocalData.scoreHistory;

  if (!Array.isArray(storedScoreHistory)) {
    storedScoreHistory = [];
  }

  const updatedScoreHistory = [...storedScoreHistory, points];

  storedLocalData.scoreHistory = updatedScoreHistory;
  localStorage.setItem(urlGameCode + '_data', JSON.stringify(storedLocalData));
}

export function undoLastScoreUpdate(urlGameCode, prevGuesser) {
  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));

  // Get the scoreHistory array from local storage
  const storedScoreHistory = storedLocalData && storedLocalData.scoreHistory;

  // Check if storedScoreHistory exists and has at least one entry
  if (Array.isArray(storedScoreHistory) && storedScoreHistory.length > 0) {
    // Get the last score from the scoreHistory array
    const lastScore = storedScoreHistory[storedScoreHistory.length - 1];

    // Create a copy of the storedReadyPlayers array
    let storedReadyPlayers = storedLocalData && storedLocalData.readyPlayers;
    // let storedReadyPlayers = JSON.parse(localStorage.getItem(urlGameCode + '_readyPlayers'));
    storedReadyPlayers = storedReadyPlayers.map(player => ({ ...player }));

    // Subtract the last score from the points of the prevGuesser
    for (let i = 0; i < storedReadyPlayers.length; i++) {
      if (storedReadyPlayers[i].name === prevGuesser) {
        storedReadyPlayers[i].score -= lastScore;
        break;
      }
    }

    // Remove the last score from the scoreHistory array
    storedScoreHistory.pop();

    // Update the local storage with the updated values
    storedLocalData.readyPlayers = storedReadyPlayers;
    storedLocalData.scoreHistory = storedScoreHistory;
    localStorage.setItem(urlGameCode + '_data', JSON.stringify(storedLocalData));
  }
}

export function getNumOfCards(urlGameCode) {
  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
  const storedOrderOfPrompts = storedLocalData && storedLocalData.orderOfPrompts;
  // const storedOrderOfPrompts = JSON.parse(localStorage.getItem(urlGameCode + '_orderOfPrompts'));
  const numOfCards = storedOrderOfPrompts.length * 2;
  return numOfCards;
}




/////////////////////////////////////
/////////// RESULTS CARD ////////////
/////////////////////////////////////

export const sortPlayers = urlGameCode => {
  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
  const storedReadyPlayers = storedLocalData && storedLocalData.readyPlayers;

  const playersCopy = storedReadyPlayers.map(player => {
    return { ...player };
  });

  playersCopy.sort((a, b) => b.score - a.score);
  return playersCopy;
};

export const addRankings = players => {
  players.forEach((player, index) => {
    if (index > 0 && player.score === players[index - 1].score) {
      player.ranking = players[index - 1].ranking;
    } else {
      player.ranking = index + 1;
    }
  });
  return players;
}

export const getHighestScores = players => {
  return players
    .filter(p => p.score === players[0].score)
    .map(p => p.score);
}

export function getTopPlayers(topCount, urlGameCode) {
  console.log('topCount: ', topCount);
  console.log('urlGameCode: ', urlGameCode);

  const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
  const storedReadyPlayers = storedLocalData && storedLocalData.readyPlayers;

  const playersCopy = storedReadyPlayers.map(player => {
    return { ...player };
  });

  // Sort players by score
  const sortedPlayers = playersCopy.slice().sort((a, b) => b.score - a.score);

  // Rank players
  sortedPlayers.forEach((player, index) => {
    player.rank = index + 1;
  });

  // Group ranks if tie
  for (let i = 0; i < sortedPlayers.length; i++) {
    if (i > 0 && sortedPlayers[i].score === sortedPlayers[i - 1].score) {
      sortedPlayers[i].rank = sortedPlayers[i - 1].rank;
    }
  }

  // Filter to top ranks
  const topRankedPlayers = sortedPlayers
    .filter(p => p.rank <= topCount)
    .map(p => ({ ...p })); // copy player objects

  return topRankedPlayers;

}

export function getTopPlayersWithEmojis(topCount, urlGameCode) {

  // Sort and rank players
  const sortedPlayers = getTopPlayers(topCount, urlGameCode);

  // Add emoji by rank
  sortedPlayers.forEach(player => {
    switch (player.rank) {
      case 1:
        player.emoji = '🥇';
        break;
      case 2:
        player.emoji = '🥈';
        break;
      case 3:
        player.emoji = '🥉';
        break;
      default:
        player.emoji = '🌟';
    }
  });

  return sortedPlayers;

}

export function getFirstPlaceFinishMessage(urlGameCode) {

  // Get top players 
  const topPlayers = getTopPlayers(1, urlGameCode);

  // Get top ranked players with rank 1
  const topRanked = topPlayers.filter(p => p.rank === 1);

  let message;

  if (topRanked.length === 1) {
    // One top ranked player
    const name = topRanked[0].name;
    message = `Your winner is ${name}`;
  } else {
    // Multiple top ranked players; get names
    const names = topRanked.map(p => p.name).join(' & ');
    message = `Your winners are ${names}`;
  }

  return message;
}