import { db } from '../../firebase';
import { doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';

export const generateUniqueGameCode = async () => {

  let code;
  let gameSessionRef;
  let docSnap = null;

  do {
    code = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 4; i++) {
      code += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    gameSessionRef = doc(db, 'gameSessions', code);
    docSnap = await getDoc(gameSessionRef);

  } while (docSnap && docSnap.exists());

  try {
    await setDoc(gameSessionRef, {
      code: code,
      createdAt: Timestamp.now()
    });
    console.log('Unique game code generated and written to DB', code);

  } catch (error) {
    console.error('Error writing game code to database', code);
  }

  return code;
}

export const checkIfGameCodeExists = async (gameCode) => {
  const gameSessionRef = doc(db, 'gameSessions', gameCode);
  const docSnap = await getDoc(gameSessionRef);

  if (!docSnap.exists()) {
    return { exists: false };
  }

  const data = docSnap.data();
  const createdAt = data.createdAt.toDate();
  const now = new Date();
  
  const expired = (now - createdAt) / (1000 * 60 * 60) > 24;

  return {
    exists: true,
    expiredCode: expired
  };
}