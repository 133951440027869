// simpleGameUtils.js
import data from '../../data2';
import * as amplitude from '@amplitude/analytics-browser';

export const updateSimpleModeContent = (
  userProfile,
  setCurrentCardDescription,
  setCurrentCardIndex,
  updateUserProfile,
  currentCardNumber,
  setCurrentCardNumber,
  setIsLastCardShowing,
  setNextButtonDisabled,
  setLikeClicked,
  setDislikeClicked,
  isGameActive,
  setIsGameActive,
  explicitCounter,
  setExplicitCounter,
) => {
  resetLikedDislikedButtons(setLikeClicked, setDislikeClicked);

  const dataCopy = filterCards(data, userProfile);
  const nextPrompt = getNextCard(dataCopy, userProfile, explicitCounter, setExplicitCounter);
  updateGameState(nextPrompt, setCurrentCardDescription, setCurrentCardIndex);
  handleUserProfileUpdate(nextPrompt, userProfile, updateUserProfile);

  if (currentCardNumber < 16) {
    trackNumberOfCardsPlayed(
      currentCardNumber,
      setCurrentCardNumber,
      setIsLastCardShowing,
      setNextButtonDisabled,
      updateUserProfile,
      userProfile
    );
  } else {
    console.log('game over already one card ago');
  }

  if (!isGameActive) { // not sure if we really need this anymore
    setIsGameActive(true);
  }
}

const resetLikedDislikedButtons = (setLikeClicked, setDislikeClicked) => {
  setLikeClicked(false);
  setDislikeClicked(false);
}

const filterCards = (data, userProfile) => {
  let dataCopy = [...data];

  if (!userProfile.includeExplicitContent) {
    dataCopy = dataCopy.filter(item => !item.explicit);
  }

  dataCopy = dataCopy.filter(card => !userProfile.playedCards.includes(card.id));
  dataCopy = dataCopy.sort(() => Math.random() - 0.5);

  return dataCopy;
}

const getNextCard = (dataCopy, userProfile, explicitCounter, setExplicitCounter) => {

  let nextPrompt = null;

  for (let i = 0; i < userProfile.preferences.length; i++) {
    const preference = userProfile.preferences[i];

    for (let j = 0; j < dataCopy.length; j++) {
      const card = dataCopy[j];

      if (card.type === preference && !userProfile.includeExplicitContent) {
        // make all cards non-explicit when the user has not selected to include explicit content
        nextPrompt = card;
        break;
      }

      if (card.type === preference && userProfile.includeExplicitContent && explicitCounter % 3 === 0 && !card.explicit) {
        // make every 3rd card non-explicit when the user has selected to include explicit content
        nextPrompt = card;
        setExplicitCounter(prevCount => prevCount + 1);
        break;
      }

      if (card.type === preference && userProfile.includeExplicitContent && card.explicit) {
        // make all other cards explicit when the user has selected to include explicit content
        nextPrompt = card;
        setExplicitCounter(prevCount => prevCount + 1);
        break;
      }

    }

    if (nextPrompt) break;
  }

  return nextPrompt;
}

const updateGameState = (nextPrompt, setCurrentCardDescription, setCurrentCardIndex) => {
  if (nextPrompt) {
    setCurrentCardDescription(nextPrompt.description);
    console.log(nextPrompt.id);
    setCurrentCardIndex(nextPrompt.id);
  }
}

const handleUserProfileUpdate = (nextPrompt, userProfile, updateUserProfile) => {
  updateUserProfile({
    playedCards: [...userProfile.playedCards, nextPrompt.id]
  });
}

const showLastCard = (
  setIsLastCardShowing,
  setNextButtonDisabled,
  updateUserProfile,
  userProfile
) => {
  setIsLastCardShowing(true);
  setNextButtonDisabled(true);
  amplitude.track('Finish Simple Mode');
  updateUserProfile({ numberOfGamesFinished: userProfile.numberOfGamesFinished + 1 });
}

const trackNumberOfCardsPlayed = (
  currentCardNumber,
  setCurrentCardNumber,
  setIsLastCardShowing,
  setNextButtonDisabled,
  updateUserProfile,
  userProfile
) => {

  let cardNumber = currentCardNumber;
  if (cardNumber === 0) {
    console.log('card number:', cardNumber);
    setCurrentCardNumber(1);
  } else {
    console.log('card number:', cardNumber);
    setCurrentCardNumber(prevCount => prevCount + 1);
  }

  if (cardNumber === 15) {
    showLastCard(
      setIsLastCardShowing,
      setNextButtonDisabled,
      updateUserProfile,
      userProfile
    );
  }
};

