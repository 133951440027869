import React, { useState } from 'react';
import { Box, Typography, Stack } from "@mui/material";
import { simpleSteps, creativeSteps } from '../../data';
import thumbnail from '../media/explainer_thumbnail.png';
import drink_if_you_think_explainer from '../media/drink_if_you_think_explainer.mp4';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const RuleCard = () => {
    const [expandedSimple, setExpandedSimple] = useState(true);
    const [expandedCreative, setExpandedCreative] = useState(true);

    return (
        <Box borderRadius="10px" sx={{ m: 2, p: 2, bgcolor: 'quaternary.main' }}>

            {/* Video explainer */}
            <Box sx={{ textAlign: 'center', py: 2 }}>
                <video
                    controls
                    poster={thumbnail}
                    style={{ maxWidth: '80vw' }}
                >
                    <source src={drink_if_you_think_explainer} type="video/mp4" />
                </video>

            </Box>

            {/* Simple steps */}
            <Stack direction="row" alignItems="center" sx={{ mt: 3 }} onClick={() => setExpandedSimple(!expandedSimple)}>
                <Typography variant="body1" fontWeight="bold" color="primary">
                    Simple Mode Steps
                </Typography>
                {expandedSimple ? <ExpandLessIcon color='primary' /> : <ExpandMoreIcon color='primary' />}
            </Stack>

            {expandedSimple &&
                <Stack component="ol" spacing={1}>
                    {Object.entries(simpleSteps).map(([key, steps]) => (
                        <Typography variant="body1" component="li" key={key}>
                            {steps}
                        </Typography>
                    ))}
                </Stack>
            }

            {/* Creative rules */}
            <Stack direction="row" alignItems="center" sx={{ mt: 3 }} onClick={() => setExpandedCreative(!expandedCreative)}>
                <Typography variant="body1" fontWeight="bold" color="primary">
                    Creative Mode Steps
                </Typography>
                {expandedCreative ? <ExpandLessIcon color='primary' /> : <ExpandMoreIcon color='primary' />}
            </Stack>

            {expandedCreative &&
                <Stack component="ol" spacing={1}>
                    {Object.entries(creativeSteps).map(([key, steps]) => (
                        <Typography variant="body1" component="li" key={key}>
                            {steps}
                        </Typography>
                    ))}
                </Stack>
            }
        </Box>
    );
}

export default RuleCard;