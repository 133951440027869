import React, { useContext, useState } from 'react';
import { Typography } from "@mui/material";
import { GameContext } from '../GameContext';
import { useLocation, useParams } from 'react-router-dom';

const PromptCounter = () => {
    const { currentCardNumber, updateUserProfile, userProfile } = useContext(GameContext);
    const [newGameStarted, setNewGameStarted] = useState(false);
    const location = useLocation();
    const { urlGameCode } = useParams();

    const isSimpleModeGamePage = location.pathname.includes("/simple-mode/play");

    if (currentCardNumber + 1 === 2 && !newGameStarted) {
        updateUserProfile({ numberOfGamesStarted: userProfile.numberOfGamesStarted + 1 });
        setNewGameStarted(true);
    }
    if (currentCardNumber === 16 && newGameStarted) {
        setNewGameStarted(false);
    }

    const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
    const cardNum = storedLocalData && storedLocalData.currentCardNumber ? storedLocalData.currentCardNumber : '🫠';

    // Calculate total prompts based on number of players
    const storedReadyPlayers = storedLocalData && storedLocalData.readyPlayers ? storedLocalData.readyPlayers : null;
    const numberOfPlayers = storedReadyPlayers?.length; 

    let totalPrompts = 0;
    if (isSimpleModeGamePage) {
        totalPrompts = 16;
    } else if (numberOfPlayers <= 5) {
        totalPrompts = numberOfPlayers * 3 * 2;
    } else if (numberOfPlayers >= 6) {
        totalPrompts = numberOfPlayers * 2 * 2;
    } else {
        totalPrompts = 16;
    }



    return (
        <Typography variant="body1" >
            {isSimpleModeGamePage ? `Card ${currentCardNumber} of ${totalPrompts}` : `${cardNum} of ${totalPrompts}`}
        </Typography>
    );
};

export default PromptCounter;
