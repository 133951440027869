import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { Box, Grid, Typography, Button, TextField, Snackbar, Alert, Stack } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import CustomTooltip from '../info/CustomTooltip';
import CreativityBank from './CreativityBank';
import { useNavigate, useParams } from 'react-router-dom';
import { GameContext } from '../GameContext';
import {
    addPlayerData,
    checkIfNameExists,
    updatePlayerData,
    updatePlayerName,
    checkIfPromptExistsForOthers,
    checkIfPlayerWroteDuplicatePrompts,
    updatePrompt,
    getPlayerInfo,
} from '../utils/dbUtils';
import { checkIfGameCodeExists } from './GameCodeUtils';
import ShareCodeButton from '../share/ShareCodeButton';
import QRCodeButton from '../share/QRCodeButton';
import CropFreeIcon from '@mui/icons-material/CropFree';

const EnterPromptsCard = () => {
    const { setGameCode, setPlayerId, setIsReady } = useContext(GameContext);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [prompt1, setPrompt1] = useState('');
    const [prompt1Error, setPrompt1Error] = useState('');
    const [prompt2, setPrompt2] = useState('');
    const [prompt2Error, setPrompt2Error] = useState('');
    const [prompt3, setPrompt3] = useState('');
    const [prompt3Error, setPrompt3Error] = useState('');
    const [open, setOpen] = useState(false)
    const navigate = useNavigate();
    const { urlGameCode } = useParams();
    const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
    const storedLocalIsHost = storedLocalData && storedLocalData.isHost;
    const storedLocalId = storedLocalData && storedLocalData.playerId;
    console.log('storedLocalId', storedLocalId);

    const restorePlayerInfo = useCallback(async (id) => {
        const playerInfo = await getPlayerInfo(urlGameCode, id);
        if (playerInfo.name !== '') {
            setName(playerInfo.name);
        }
        setPrompt1(playerInfo.prompts[0]);
        setPrompt2(playerInfo.prompts[1]);
        setPrompt3(playerInfo.prompts[2]);
    }, [urlGameCode, setPrompt1, setPrompt2, setPrompt3, setName]);

    // potentially move to parent component enter-prompts page
    const checkGameCode = useCallback(() => {
        checkIfGameCodeExists(urlGameCode).then(async result => {
            const player = {
                name: "",
                isAddingPrompts: true,
                isReady: false,
                isHost: false, // could lead to a bug if Host rejoins via link after starting game
                prompts: [],
            };

            if (result.expiredCode) {
                console.log('Game code expired');
                navigate(`/`);
            } else if (result.exists) {
                // new player is joining
                console.log('new player is joining');
                setGameCode(urlGameCode);
                const newPlayerId = await addPlayerData(urlGameCode, player);

                const data = {
                    gameId: urlGameCode,
                    isHost: false,
                    playerId: newPlayerId,
                    creationTimestamp: new Date().toISOString(),
                };

                localStorage.setItem(urlGameCode + '_data', JSON.stringify(data));
            } else {
                navigate(`/`);
                console.log('Game code does not exist');
            }

        })
    }, [urlGameCode, navigate, setGameCode]);

    useEffect(() => {

        if (storedLocalId) {
            // Host or player is rejoining
            console.log('Host or player is rejoining');
            setPlayerId(storedLocalId);
            restorePlayerInfo(storedLocalId);
            // toDo may want to add checkGameCode() here to check if game code has expired
        } else if (!storedLocalId) {
            // new player is joining
            console.log('new player is joining');
            checkGameCode();
        } else {
            console.log('error: enterPromptsCard.js: useEffect');
        }

    }, [checkGameCode, setPlayerId, restorePlayerInfo, storedLocalId, storedLocalIsHost, urlGameCode]);

    const isMounted = useRef(true);

    useEffect(() => {
        const fetchData = () => {
            if (storedLocalId && isMounted.current) {
                restorePlayerInfo(storedLocalId);
            }
        }

        fetchData();

        return () => {
            isMounted.current = false;
        }

    }, [urlGameCode, storedLocalId, restorePlayerInfo]);

    const handleNextClick = async () => {

        const nameExists = await checkIfNameExists(name, urlGameCode, storedLocalId);
        const prompt1Exists = await checkIfPromptExistsForOthers(prompt1, urlGameCode, storedLocalId);
        const prompt2Exists = await checkIfPromptExistsForOthers(prompt2, urlGameCode, storedLocalId);
        const prompt3Exists = await checkIfPromptExistsForOthers(prompt3, urlGameCode, storedLocalId);
        const dublicatePrompts = await checkIfPlayerWroteDuplicatePrompts(prompt1, prompt2, prompt3, urlGameCode, storedLocalId);

        if (!nameExists && !prompt1Exists && !prompt2Exists && !prompt3Exists && !dublicatePrompts) {
            // Pass validation

            const prompts = [prompt1, prompt2, prompt3];
            let ready = true;
            await updatePlayerData(name, prompts, urlGameCode, storedLocalId, ready);
            setIsReady(ready);
            const page = storedLocalIsHost ? 'waiting-lobby' : 'close-phone';
            navigate(`/creative-mode/${urlGameCode}/${page}`);
        }

        if (dublicatePrompts) {
            setOpen(true)
        }

    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleNameBlur = async () => {
        const nameIsEmpty = name === '';
        const doesNameExist = await checkIfNameExists(name, urlGameCode, storedLocalId);

        if (!doesNameExist) {
            await updatePlayerName(name, urlGameCode, storedLocalId);
            setNameError('');
        } else if (nameIsEmpty) {
            setNameError("Sorry new phone 🤪 Who dis?");
        } else if (doesNameExist) {
            setNameError('Name already exists. Please use a different name.');
        } else {
            setNameError('');
        }
    }

    const handlePrompt1Blur = async () => {
        const promptIsEmpty = prompt1 === '' || prompt1 === undefined;
        const promptExists = await checkIfPromptExistsForOthers(prompt1, urlGameCode, storedLocalId);

        if (promptIsEmpty) {
            setPrompt1Error("Opps. You forgot to write a prompt.");
        } else if (promptExists) {
            setPrompt1Error('Prompt already used. Please enter a new prompt.');
        } else {
            setPrompt1Error('');

            const prompts = [prompt1, prompt2, prompt3].filter(p => p !== undefined);
            await updatePrompt(prompts, urlGameCode, storedLocalId);
        }
    }

    const handlePrompt2Blur = async () => {
        const promptIsEmpty = prompt2 === '' || prompt2 === undefined;
        const promptExists = await checkIfPromptExistsForOthers(prompt2, urlGameCode, storedLocalId);

        if (promptExists) {
            setPrompt2Error('Prompt already used. Please enter a new prompt.');
        } else if (promptIsEmpty) {
            setPrompt2Error("Opps. You forgot to write a prompt.");
        } else {
            setPrompt2Error('');

            const prompts = [prompt1, prompt2, prompt3].filter(p => p !== undefined);
            await updatePrompt(prompts, urlGameCode, storedLocalId);
        }
    }

    const handlePrompt3Blur = async () => {
        const promptIsEmpty = prompt3 === '' || prompt3 === undefined;
        const promptExists = await checkIfPromptExistsForOthers(prompt3, urlGameCode, storedLocalId);

        if (promptExists) {
            setPrompt3Error('Prompt already used. Please enter a new prompt.');
        } else if (promptIsEmpty) {
            setPrompt3Error("Opps. You forgot to write a prompt.");
        } else {
            setPrompt3Error('');

            const prompts = [prompt1, prompt2, prompt3].filter(p => p !== undefined);
            await updatePrompt(prompts, urlGameCode, storedLocalId);
        }
    }


    return (
        <div>
            <Box borderRadius="10px" sx={{ m: 2, p: 2, bgcolor: 'quaternary.main', opacity: 0.8 }}>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid container xs={8} >
                                <Typography variant="body1">Your name & prompts</Typography>
                                <CustomTooltip
                                    title="Enter your name and prompts below. Each player will submit their own prompts to be used during the game."
                                >
                                    <HelpIcon fontSize='small' sx={{ pl: 1 }} />
                                </CustomTooltip>
                            </Grid>
                            <Grid container item xs={4} direction="column" alignItems="center" justifyContent="center">
                                <Stack direction="row">
                                <ShareCodeButton
                                    code={urlGameCode}
                                    iconProps={{ color: 'primary' }}
                                />
                                <QRCodeButton
                                    code={urlGameCode}
                                    buttonIcon={<CropFreeIcon sx={{ color: 'primary'}}/>}
                                />
                                </Stack>
                                <Typography variant="body2" color='black'>
                                    {urlGameCode}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="name"
                            label="Your Name"
                            variant="standard"
                            multiline
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={handleNameBlur}
                            error={!!nameError}
                            helperText={nameError}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="prompt1"
                            label="Drink if you think..."
                            variant="standard"
                            multiline
                            value={prompt1}
                            onChange={(e) => setPrompt1(e.target.value)}
                            onBlur={handlePrompt1Blur}
                            error={!!prompt1Error}
                            helperText={prompt1Error}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="prompt2"
                            label="Drink if you think..."
                            variant="standard"
                            multiline
                            value={prompt2}
                            onChange={(e) => setPrompt2(e.target.value)}
                            onBlur={handlePrompt2Blur}
                            error={!!prompt2Error}
                            helperText={prompt2Error}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                       <TextField
                         id="prompt3"
                         label="Drink if you think..."
                         variant="standard"
                         multiline
                         value={prompt3}
                         onChange={(e) => setPrompt3(e.target.value)}
                         onBlur={handlePrompt3Blur}
                         error={!!prompt3Error}
                         helperText={prompt3Error || (prompt3 && "With 6+ players, we may not use this prompt to keep the game to ~15 mins.")}
                         fullWidth
                       />
                    </Grid>

                    <Grid item xs={12} justifyContent={'center'}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CreativityBank />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNextClick}
                                disabled={
                                    nameError || !name ||
                                    prompt1Error || !prompt1 ||
                                    prompt2Error || !prompt2 ||
                                    prompt3Error || !prompt3
                                }
                                sx={{
                                    borderRadius: '10px',
                                    fontWeight: 'bold',
                                    color: 'secondary.main',
                                }}
                            >
                                Next
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box >

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert variant="filled" severity="error">Dublicate prompt. Please make sure all prompts are different.</Alert>
            </Snackbar>
        </div>
    );
};

export default EnterPromptsCard;