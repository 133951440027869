import React, { useContext } from 'react';
import { Box, Grid } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import PromptCounter from './PromptCounter';
import LikeDislikeButton from './LikeDislikeButton';
import PromptContainer from './PromptContainer';
import CustomTooltip from '../info/CustomTooltip';
import { useLocation } from 'react-router-dom';
import { GameContext } from '../GameContext';

const ContentCard = () => {
    const { currentCardType } = useContext(GameContext);
    const location = useLocation();
    const isSimpleMode = location.pathname.startsWith("/simple-mode/");
    const isCreativeMode = location.pathname.startsWith("/creative-mode/");
    const isGuessCard = currentCardType === 'guess';

    return (
        <Box
            borderRadius="10px"
            sx={{
                m: 2,
                p: 2,
                alignItems: 'center',
                bgcolor: 'quaternary.main',
                opacity: 0.80,
            }}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <PromptCounter />
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {isSimpleMode && <LikeDislikeButton />}
                    {isCreativeMode && isGuessCard &&
                     <CustomTooltip
                     title="Guess who wrote the prompt. If you guess correctly, you get points! If not, you loose points."
                 >
                         <HelpIcon fontSize='small'/>
                 </CustomTooltip>
                    }
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <PromptContainer />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ContentCard;
