//PlayerStatus.js
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ShareCodeButton from '../share/ShareCodeButton';
import { styled } from '@mui/material/styles';
import { GameContext } from '../GameContext';
import { getPlayersStatus } from '../utils/dbUtils';
import { useParams, useLocation } from 'react-router-dom';


const CircleContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1), // Adjust padding as needed
  margin: 'auto', // Center horizontally
  cursor: 'pointer', // Add cursor pointer for click interaction
}));

const Emoji = styled('div')(({ theme }) => ({
  fontSize: '40px', // Adjust font size to fit the circle
}));

const emojis = [
  '😄', '🥳', '😎', '🤪',
  '🤩', '🥸', '🤓', '😜',
  '😝', '🤑', '😈', '👹',
  '👺', '💩', '🤡', '🤠',
  '🤖', '👽', '💀', '☠️',
  '😸', '😹', '😻', '😼',
  '😽', '🙀', '😿', '😾',
  '🤲', '👐', '🙌'
];

const PlayerStatus = () => {
  const { setGameCode, playerId, players, setPlayers } = useContext(GameContext);
  const { urlGameCode } = useParams();
  const location = useLocation();
  setGameCode(urlGameCode);
  const onSharePage = location.pathname.includes('/share');

  const placeholderUsers = useMemo(() => {
    return [
      {
        id: 1,
        emoji:
          <ShareCodeButton
            code={urlGameCode}
            icon={PersonAddIcon}
            iconProps={{ color: 'secondary' }}
          />,
        isPlaceholder: true
      },
      {
        id: 2,
        emoji:
          <ShareCodeButton
            code={urlGameCode}
            icon={PersonAddIcon}
            iconProps={{ color: 'secondary' }}
          />,
        isPlaceholder: true
      },
      {
        id: 3,
        emoji:
          <ShareCodeButton
            code={urlGameCode}
            icon={PersonAddIcon}
            iconProps={{ color: 'secondary' }}
          />,
        isPlaceholder: true
      },
      {
        id: 4,
        emoji:
          <ShareCodeButton
            code={urlGameCode}
            icon={PersonAddIcon}
            iconProps={{ color: 'secondary' }}
          />,
        isPlaceholder: true
      },
    ]
  }, [urlGameCode]);

  const fetchData = useCallback(() => {
    let unsubscribe;

    unsubscribe = getPlayersStatus(urlGameCode).subscribe(updatedPlayers => {
      console.log('StatusBarUpdated: ', updatedPlayers);
      const playerNumber = Number(playerId.slice(6)); // get number

      const mappedPlayers = updatedPlayers.map(player => {
        const id = Number(player.id.slice(6)); // extract number from id
        const name = player.name
          ? player.name
          : id === playerNumber ? 'Me' : `Silly ${id}`;

        return {
          id,
          emoji: emojis[id - 1] || '🙂',
          name,
          isReady: player.isReady,
          isPlaceholder: false
        };
      });

      const numPlaceholders = Math.max(4 - mappedPlayers.length, 0);
      const updatedUsers = [...mappedPlayers, ...placeholderUsers.slice(0, numPlaceholders)];

      setPlayers(updatedUsers);
    });

    return () => unsubscribe && unsubscribe();
  }, [setPlayers, urlGameCode, playerId, placeholderUsers]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getStatusText = (user) => {
    if (onSharePage || user.isPlaceholder) {
      return null;
    } else {
      return user.isReady ? 'Ready ✅' : 'Writing ✍️';
    }
  }

  return (
    <Grid
      container
      spacing={1}
      padding={2}
      alignItems="flex-start"
    >
      {players.map((user) => (
        <Grid
          item xs={3}
          key={user.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <CircleContainer
            elevation={3}
          >
            <Emoji>{user.emoji}</Emoji>
          </CircleContainer>
          <Typography align="center" variant="body1" m={1}>
            {user.name}
          </Typography>
          {user.isPlaceholder || onSharePage
            ? null
            : <Box
              sx={{
                p: 1,
                mb: 1,
                maxWidth: '120px',
                bgcolor: 'secondary.main',
                borderRadius: '10px'
              }}>
              <Typography align="center" variant="body2">
                {getStatusText(user)}
              </Typography>
            </Box>}
        </Grid>
      ))}
    </Grid>
  );
};

export default PlayerStatus;
