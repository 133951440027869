import React, { useContext, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import { GameContext } from '../GameContext';
import { useTheme } from '@mui/material/styles';

const defaultOptions = [
  'old friends',
  'new friends',
  'old couples',
  'new couples',
  'family',
];

const orderedPreferences = {
  'new couples': ['new couples', 'new friends', 'old couples', 'old friends', 'family'],
  'new friends': ['new friends', 'old friends', 'family', 'new couples', 'old couples'],
  'old couples': ['old couples', 'old friends', 'family', 'new couples', 'new friends'],
  'family': ['family', 'old friends', 'new friends', 'new couples', 'old couples'],
  'old friends': ['old friends', 'family', 'new friends', 'new couples', 'old couples']
};


const CustomAutocomplete = () => {
  const { userProfile, updateUserProfile } = useContext(GameContext);
  const theme = useTheme();

  useEffect(() => {
    // Set the initial value of the Autocomplete component from the userProfile context
    if (userProfile.selectedGroupTypes) {
      setValue(userProfile.selectedGroupTypes);
    }
  }, [userProfile.selectedGroupTypes]);

  const [value, setValue] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Update the user's profile with the selected tags
    updateUserProfile({ selectedGroupTypes: newValue });

    if (newValue.length > 0) {
      const selectedPreference = newValue[0];
      const preferenceArray = orderedPreferences[selectedPreference];
  
      if (preferenceArray) {
        updateUserProfile({ preferences: preferenceArray });
      }
    }
  };

  const handleRenderTags = (tagValue, getTagProps) =>
    tagValue.map((option, index) => (
      <Chip
        {...getTagProps({ index })}
        label={option}
        color="primary"
        sx={{
          marginBottom: '8px',
          color: theme.palette.common.white,
          '& .MuiChip-deleteIcon': {
            color: theme.palette.secondary.main,
          },
        }}
      />
    ));

  return (
    <Box mb={1}>
      <Autocomplete
        multiple
        limitTags={5}
        value={value}
        onChange={handleChange}
        options={defaultOptions}
        renderTags={handleRenderTags}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Who are you with?"
            placeholder="Type here..."
            InputProps={{
              ...params.InputProps,
              style: {
                display: 'flex',
                flexWrap: 'wrap',
              },
            }}
          />
        )}
        renderOption={(props, option) => <li {...props}>{option}</li>}
      />
    </Box>
  );
};

export default CustomAutocomplete;
