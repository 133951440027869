//dbUtils.js
import { db } from '../../firebase';
import { doc, setDoc, getDocs, getDoc, collection, onSnapshot, updateDoc, runTransaction } from 'firebase/firestore';

export const addPlayerData = async (code, player) => {

  const gameRef = doc(db, "gameSessions", code);

  let nextPlayerId;

  await runTransaction(db, async transaction => {

    const gameDoc = await transaction.get(gameRef);

    nextPlayerId = gameDoc.data().nextPlayerId;

    if (!nextPlayerId) {
      nextPlayerId = 1;
      transaction.set(gameRef, { nextPlayerId }, { merge: true });
    } else {
      nextPlayerId++;
      transaction.update(gameRef, { nextPlayerId });
    }

    return nextPlayerId;

  });

  const playerRef = doc(gameRef, 'players', `player${nextPlayerId}`);

  await setDoc(playerRef, {
    name: player.name,
    isAddingPrompts: player.isAddingPrompts,
    isReady: player.isReady,
    isHost: player.isHost,
    prompts: player.prompts
  });

  return `player${nextPlayerId}`
}

export const checkIfNameExists = async (name, gameCode, playerId) => {

  const playersRef = collection(db, 'gameSessions', gameCode, 'players');

  const snapshot = await getDocs(playersRef);

  let nameExists = false;

  snapshot.forEach(doc => {
    if (doc.id !== playerId) {

      if (doc.data().name === name) {
        nameExists = true;
      }

    }
  });

  return nameExists;

}

export const checkIfPromptExistsForOthers = async (prompt, gameCode, playerId) => {
  // check if the player wrote the same prompt as another player

  const playersRef = collection(db, 'gameSessions', gameCode, 'players');

  const snapshot = await getDocs(playersRef);

  let promptExists = false;

  snapshot.forEach(doc => {
    if (doc.id !== playerId) {
      if (doc.data().prompts.includes(prompt)) {
        promptExists = true;
      }
    }
  });

  return promptExists;

}

export const checkIfPlayerWroteDuplicatePrompts = async (prompt1, prompt2, prompt3, gameCode, playerId) => {
  // check if the player wrote the same prompt twice

  const playerRef = doc(db, 'gameSessions', gameCode, 'players', playerId);

  const docSnapShot = await getDoc(playerRef);

  const prompts = docSnapShot.data().prompts;

  let duplicate = false;

  if (prompts.filter(p => p === prompt1).length >= 2) {
    duplicate = true;
  }
  if (prompts.filter(p => p === prompt2).length >= 2) { 
    duplicate = true;
  }
  if (prompts.filter(p => p === prompt3).length >= 2) {
    duplicate = true;
  }

  return duplicate;

}

export const updatePlayerName = async (name, gameCode, playerId) => {
  const playerRef = doc(db, 'gameSessions', gameCode, 'players', playerId);

  await updateDoc(playerRef, { name: name });
}

export const updatePrompt = async (prompts, gameCode, playerId) => {

  const playerRef = doc(db, 'gameSessions', gameCode, 'players', playerId);

  await setDoc(playerRef, { prompts }, { merge: true });

}


export const updatePlayerData = async (name, prompts, gameCode, playerId, isReady) => {
  const playerRef = doc(db, 'gameSessions', gameCode, 'players', playerId);

  await setDoc(playerRef, {
    name,
    prompts,
    isReady
  }, { merge: true });

}

export const getPlayersStatus = (gameCode) => {

  return {

    subscribe: (callback) => {

      const gameDocRef = doc(db, 'gameSessions', gameCode);
      const playersRef = collection(gameDocRef, 'players');

      const unsubscribe = onSnapshot(playersRef, snapshot => {

        const updatedPlayers = [];

        snapshot.forEach(doc => {
          const id = doc.id;
          const data = doc.data();

          updatedPlayers.push({
            ...data,
            id
          });
        });

        callback(updatedPlayers);
      });

      console.log("unsubscribe: ", unsubscribe);

      return unsubscribe;
    }

  }

}

export const getReadyPlayers = async (gameCode) => {

  const gameDocRef = doc(db, 'gameSessions', gameCode);

  const playersRef = collection(gameDocRef, 'players');

  const playerDocs = await getDocs(playersRef);

  const readyPlayers = [];

  playerDocs.forEach(doc => {

    const player = doc.data();

    if (player.isReady) {
      readyPlayers.push({
        playerId: doc.id,
        name: player.name,
        unusedPrompts: player.prompts,
        usedPrompts: [],
        thinkTurns: 0,
        guessTurns: 0,
        score: 0
      });
    }

  });

  return readyPlayers;

}

export const addReadyPlayersToDB = async (code, players) => {

  const sessionRef = doc(db, 'gameSessions', code);
  let gameNum = 1;

  const gameRef = doc(sessionRef, 'games', `game${gameNum}`);

  players.forEach(async player => {

    const playerRef = doc(gameRef, 'players', player.playerId); 

    await setDoc(playerRef, {
      name: player.name,
      unusedPrompts: player.unusedPrompts, 
      usedPrompts: player.usedPrompts,
      thinkturns: player.thinkTurns,
      guessturns: player.guessTurns,
      score: player.score,
      playerId: player.playerId
    });

  });

  return gameNum;

}

export const getPlayerInfo = async (gameCode, playerId) => {
  console.log('getPlayerInfo');
  
  const playerRef = doc(db, 'gameSessions', `${gameCode}`, 'players',`${playerId}`);
  const playerDoc = await getDoc(playerRef);
  
  if (playerDoc.exists()) {
    const name = playerDoc.data().name;
    const prompts = playerDoc.data().prompts;

    console.log('Name:', name);
    console.log('Prompts:', prompts);
    
    await setDoc(playerRef, {
      isReady: false
    }, { merge: true });

    return {
      name,
      prompts
    }
  }

  return null;
  
}



// Todo: 

// update addReadyPlayersToDB to account for multiple games
// const gameDocs = await getDocs(collection(sessionRef, 'games'));
// if (gameDocs.docs.length > 0) {
//   const ids = gameDocs.docs.map(doc => doc.id);
//   gameNum = Math.max(...ids.map(id => parseInt(id.slice(4)))) + 1;
//   console.log('In IF Statement: gameNum: ', gameNum);
// }