import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box, ThemeProvider, createTheme } from '@mui/material';
import Header from './components/app_bar/Header';
import LandingPage from './pages/LandingPage';
import ShareCodePage from './pages/ShareCodePage';
import EnterPromptsPage from './pages/EnterPromptsPage';
import WaitingLobbyPage from './pages/WaitingLobbyPage';
import PersonalizePage from './pages/PersonalizePage';
import ResultsPage from './pages/ResultsPage';
import ClosePhonePage from './pages/ClosePhonePage';
import GamePlayPage from './pages/GamePlayPage';
import { red } from '@mui/material/colors';
import HomePage from './components/HomePage';
import Layout from './Layout';
import * as amplitude from '@amplitude/analytics-browser';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3DB2FF',
    },
    secondary: {
      main: '#F0F0F0',
    },
    tertiary: {
      main: '#FFB830',
    },
    quaternary: {
      main: '#D9D9D9',
    },
    fifth: {
      main: '#FFEDDA',
    },
    typography: {
      h4: {
        // base h4 styles 
      },
      winnerName: {
        color: red,
        extend: 'h4'
      }
    }
  },
});

function App() {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, { defaultTracking: { sessions: true, }, });

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Box>
        <Routes>
          <Route path="/" element={<Layout><LandingPage /></Layout>} />

          <Route path="/simple-mode">
            <Route index element={<Layout><h1>Simple Mode</h1></Layout>} />
            <Route path="personalize" element={<Layout><PersonalizePage /></Layout>} />
            <Route path="play" element={<Layout><HomePage /></Layout>} />
          </Route>

          <Route path="/creative-mode/:urlGameCode" >
            <Route index element={<Layout><h1>Creative Mode</h1></Layout>} />
            <Route path="share" element={<Layout><ShareCodePage /></Layout>} />
            <Route path="enter-prompts" element={<Layout><EnterPromptsPage /></Layout>} />
            <Route path="waiting-lobby" element={<Layout><WaitingLobbyPage /></Layout>} />
            <Route path="close-phone" element={<Layout><ClosePhonePage /></Layout>} />
            <Route path="play" element={<Layout><GamePlayPage /></Layout>} />
            <Route path="results" element={<Layout><ResultsPage /></Layout>} />
          </Route>

          <Route path="*" element={<h1>404 Not Found</h1>} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
}

export default App;
