// Layout.js

import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import backgroundImageMobile from './components/media/jr_background_mobile.png';
import backgroundImageDesktop from './components/media/jr_background_desktop.png';


const Layout = ({ children }) => {

    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    return (
        <Box
            sx={{
                minHeight: '100vh',
                minWidth: '100vw',
                position: 'relative',
                backgroundImage: isDesktop ? `url(${backgroundImageDesktop})` : `url(${backgroundImageMobile})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                overflow: 'hidden',                
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    zIndex: 1,
                }}
            />
            <Box sx={{ 
                zIndex: 2,
                position: 'relative',
                mt: 10,
                }}>
            {children}
            </Box>
        </Box>
    );

};

export default Layout;