// conditional logic to show creative mode components and flow. at a higher level have truehome
import React from 'react'
import { Box } from '@mui/system'
import ClosePhoneMessageCard from '../components/pre_game/ClosePhoneMessageCard';
import RuleCard from '../components/app_bar/RuleCard';
import FormCard from '../components/feedback_card/FormCard';
import ShareCard from '../components/share/ShareCard';

function ClosePhonePage() {

    return (
        <Box>
            <ClosePhoneMessageCard />
            <RuleCard />
            <FormCard />
            <ShareCard />
        </Box>
    )
}

export default ClosePhonePage
