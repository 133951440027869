import React from 'react';
import { Typography, Grid } from '@mui/material';
import { sortPlayers, addRankings, getHighestScores } from '../../utils/gameUtils.js';
import { useParams } from 'react-router-dom';

const ResultsTable = () => {
    const { urlGameCode } = useParams();

    const sortedPlayers = sortPlayers(urlGameCode);

    const rankedPlayers = addRankings(sortedPlayers);
    
    const highestScores = getHighestScores(rankedPlayers);

    return (
        <Grid container spacing={1} mt={2}>

            {sortedPlayers.map(player => (
                <React.Fragment key={player.id}>

                    <Grid item xs={6}>
                        <Typography
                            variant={player.ranking === 1 ? 'h6' : 'body1'}
                            fontWeight={player.ranking === 1 ? 'bold' : 'normal'}
                        >
                            {player.ranking} {player.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography
                            textAlign="right"
                            variant={highestScores.includes(player.score) ? 'h6' : 'body1'}
                            fontWeight={highestScores.includes(player.score) ? 'bold' : 'normal'}
                        >
                            {player.score} pts
                        </Typography>
                    </Grid>

                </React.Fragment>
            ))}

        </Grid>
    );
}

export default ResultsTable;