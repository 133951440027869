import React from 'react';
import { Typography, Grid, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Podium from './Podium';
import { getTopPlayersWithEmojis } from '../../utils/gameUtils';
import { useParams } from 'react-router-dom';

const PodiumPlayers = () => {
    const { urlGameCode } = useParams();

    // ToDos
    // move this to a reusable component
    const CircleContainer = styled(Paper)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(1), // Adjust padding as needed
        margin: 'auto', // Center horizontally
        cursor: 'pointer', // Add cursor pointer for click interaction
    }));

    const Emoji = styled('div')(({ theme }) => ({
        fontSize: '40px', // Adjust font size to fit the circle
    }));

    let topPlayers = getTopPlayersWithEmojis(3, urlGameCode);

    // Check for ties
    const ranks = [...new Set(topPlayers.map(p => p.rank))];
    const hasTies = ranks.length < topPlayers.length;

    return (
        <Box>
            {hasTies ? (
                // Show only top players if tie
                <Grid container justify="center" >
                    {getPodiumPlayers(topPlayers, 1).map(player => (
                        <Grid item xs={12} key={player.id} align="center">
                            <CircleContainer>
                                <Emoji>{player.emoji}</Emoji>
                            </CircleContainer>
                            <Typography align="center">{player.name}</Typography>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                // Show full podium if no ties
                <Grid container justify="center">
                    {/* Top 3 point earners */}
                    <Grid container justifyContent="center">
                        {/* 2nd top point earner on the left */}
                        <Grid
                            item
                            xs={4}
                            key={topPlayers[1].id}
                            style={{
                                position: 'relative',
                                top: '25px',
                            }}
                        >
                            <CircleContainer elevation={3}>
                                <Emoji>{topPlayers[1].emoji}</Emoji>
                            </CircleContainer>
                            <Typography align="center" variant="h6" color="primary" fontWeight={'bold'}>
                                {topPlayers[1].name}
                            </Typography>
                        </Grid>

                        {/* Top point earner in the middle */}
                        <Grid
                            item
                            xs={4}
                            key={topPlayers[0].id}
                            style={{
                                position: 'relative',
                                top: '0px',
                            }}
                        >
                            <CircleContainer elevation={3}>
                                <Emoji>{topPlayers[0].emoji}</Emoji>
                            </CircleContainer>
                            <Typography align="center" variant="h6" color="primary" fontWeight={'bold'}>
                                {topPlayers[0].name}
                            </Typography>
                        </Grid>

                        {/* 3rd top point earner on the right */}
                        <Grid
                            item
                            xs={4}
                            key={topPlayers[2].id}
                            style={{
                                position: 'relative',
                                top: '50px',
                            }}
                        >
                            <CircleContainer elevation={3}>
                                <Emoji>{topPlayers[2].emoji}</Emoji>
                            </CircleContainer>
                            <Typography align="center" variant="h6" color="primary" fontWeight={'bold'}>
                                {topPlayers[2].name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Podium />
                </Grid>
            )}

        </Box>
    );
};
// Helper to filter players 
function getPodiumPlayers(players, position) {
    return players.filter(p => p.rank === position);
}

export default PodiumPlayers;
