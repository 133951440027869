import React, { createContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as Icon } from './media/js_logo_192px.svg';

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const [showOnboardingFlow, setShowOnboardingFlow] = useState(true);
  const [explicitContent, setExplicitContent] = useState(false);
  const [explicitCounter, setExplicitCounter] = useState(0);
  const [isGameActive, setIsGameActive] = useState(false);
  const [currentCardNumber, setCurrentCardNumber] = useState(0);
  const [currentCardIndex, setCurrentCardIndex] = useState(null);
const [currentCardDescription, setCurrentCardDescription] = useState("Ooops! Looks like you found an empty card. Navigate back to the previous screen");
  const [isNPSCardVisible, setNPSCardVisibility] = useState(false);
  const [isFormCardVisible, setFormCardVisibility] = useState(false);
  const [isLastCardShowing, setIsLastCardShowing] = useState(false);
  const [isPlayAgainCardVisible, setPlayAgainCardVisibility] = useState(false);
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [likeClicked, setLikeClicked] = useState(false);
  const [dislikeClicked, setDislikeClicked] = useState(false);
  const [gameMode, setGameMode] = useState('');
  const [gameCode, setGameCode] = useState('0000');
  const [playerId, setPlayerId] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [players, setPlayers] = useState([]);
  const [gameData, setGameData] = useState({});
  const [currentPlayerNames, setCurrentPlayerNames] = useState([]);
  const [currentGameMode, setCurrentGameMode] = useState('');
  const [currentGuesserIndex, setCurrentGuesserIndex] = useState(0);
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);

  
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState({
    playedCards: [],
    dislikedCards: [],
    likedCards: [],
    preferences: ['newFriends', 'oldFriends', 'family', 'newCouples', 'oldCouples'],
    selectedGroupTypes: [],
    includeExplicitContent: false,
    hasSeenOnboardingFlow: false,
    hasPlayedAllCards: false,
    netPromoterScore: [],
    netPromoterScoreShortResponse: [],
    hasClickedOnExternalSurvey: false,
    uniqueUserId: '',
    numberOfGamesFinished: 0, // need to hook up locally
    numberOfGamesStarted: 0, // need to hook up locally
    numberOfShareIconClicks: 0, // need to hook up locally

  });

  const updateUserProfile = (newProfileData) => {
    setUserProfile((prevProfile) => ({ ...prevProfile, ...newProfileData }));
  };

  useEffect(() => {
    const savedUserProfile = localStorage.getItem('userProfile');
    if (savedUserProfile) {
      setUserProfile(JSON.parse(savedUserProfile));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading) {
      localStorage.setItem('userProfile', JSON.stringify(userProfile));
    }

  }, [userProfile, loading]);

  useEffect(() => {
    const generateUniqueUserId = () => {
      if (!userProfile.uniqueUserId) {
      const deviceId = uuidv4();
      updateUserProfile({ uniqueUserId: deviceId });
    }
    };

    if (loading === false) {
      generateUniqueUserId();
    }
  }, [loading, userProfile.uniqueUserId]);

  const playAgainResetContext = () => {
    setCurrentCardNumber(0);
    setCurrentCardIndex(null);
    setNPSCardVisibility(false);
    setFormCardVisibility(false);
    setPlayAgainCardVisibility(false);
    setIsLastCardShowing(false);
    setNextButtonDisabled(false);
    setExplicitCounter(0);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ color: 'red', textAlign: 'center' }}>
          <Icon style={{ animation: 'spin 1s linear infinite' }} />
          Loading...
        </div>
      </div>
    );
  }

  return (
    <GameContext.Provider
      value={{
        showOnboardingFlow,
        setShowOnboardingFlow,
        explicitContent,
        setExplicitContent,
        isGameActive,
        setIsGameActive,
        currentCardNumber,
        setCurrentCardNumber,
        currentCardDescription,
        setCurrentCardDescription,
        isNPSCardVisible,
        setNPSCardVisibility,
        isFormCardVisible,
        setFormCardVisibility,
        isPlayAgainCardVisible,
        setPlayAgainCardVisibility,
        userProfile,
        updateUserProfile,
        currentCardIndex,
        setCurrentCardIndex,
        isNextButtonDisabled,
        setNextButtonDisabled,
        likeClicked,
        setLikeClicked,
        dislikeClicked,
        setDislikeClicked,
        playAgainResetContext,
        gameMode,
        setGameMode,
        gameCode,
        setGameCode,
        playerId,
        setPlayerId,
        isReady,
        setIsReady,
        players,
        setPlayers,
        gameData,
        setGameData,
        currentPlayerNames,
        setCurrentPlayerNames,
        currentGameMode,
        setCurrentGameMode,
        isLastCardShowing,
        setIsLastCardShowing,
        currentGuesserIndex,
        setCurrentGuesserIndex,
        currentPlayerIndex,
        setCurrentPlayerIndex,
        explicitCounter,
        setExplicitCounter,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
