import { useState, useRef, useEffect } from 'react';
import { Tooltip } from '@mui/material';

const CustomTooltip = ({ title, children, duration=8000 }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickOutside = e => {
    if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const tooltipRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, duration);
    
    return () => clearTimeout(timer);
  }, [open, duration]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Tooltip
      ref={tooltipRef}  
      title={title}
      open={open}
      onClick={handleClick} 
    >
      {children}
    </Tooltip>
  );
}

export default CustomTooltip;