// ShareCodeButton.js

import React, { useState } from 'react';
import { IosShare } from '@mui/icons-material';
import { Alert, IconButton, Snackbar } from '@mui/material';


export default function ShareCodeButton({
    code,
    icon: Icon = IosShare, // default icon
    iconProps 
  }) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {

        const codePath = `/creative-mode/${code}`

        const enterPromptsURL = `${window.location.origin}${codePath}/enter-prompts`;

        if (navigator.share) {
            navigator.share({
                title: 'Enter Prompts',
                text: 'Enter prompts for our game!',
                url: enterPromptsURL
            })
                .then(() => console.log('Shared successfully'))
                .catch(error => console.log('Error sharing', error));
        } else {
            navigator.clipboard.writeText(enterPromptsURL)
                .then(() => {
                    setOpen(true);

                    setTimeout(() => {
                        setOpen(false);
                    }, 5000);
                })
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <IconButton onClick={handleClick}>
                <Icon {...iconProps} />
            </IconButton>
            <Snackbar
                open={open}
                sx={{
                    zIndex: 1000 // high z-index
                }}
            >
                <Alert
                    variant='filled'
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    Game link with your code copied!
                </Alert>
            </Snackbar>
        </>
    )
}