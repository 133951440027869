// conditional logic to show creative mode components and flow. at a higher level have truehome
import React from 'react'
import { Box } from '@mui/system'
import JoinCard from '../components/pre_game/JoinCard';
import GameModeCard from '../components/pre_game/GameModeCard';
import RuleCard from '../components/app_bar/RuleCard';

function LandingPage() {

    return (
        <Box pb={6}>
            <JoinCard />
            <GameModeCard />
            <RuleCard />
        </Box>
    )
}

export default LandingPage
