import React, { useState, useContext } from 'react';
import { GameContext } from '../GameContext';
import { Box, Snackbar, Alert } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import * as amplitude from '@amplitude/analytics-browser';


function ShareButton() {
    const { updateUserProfile, userProfile } = useContext(GameContext);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
      };

    const message = `Check out this game https://drinkifyouthink.justrecess.com/`;

    const handleSendLink = () => {
        amplitude.track('Share Game From App Bar');
        updateUserProfile({ numberOfShareIconClicks: userProfile.numberOfShareIconClicks + 1 });

        if (navigator.share) {
            navigator.share({
                text: message,
            })
                .then(() => showSnackBar())
                .catch((error) => console.error('Error sharing:', error));
        } else if (navigator.clipboard) {
            navigator.clipboard.writeText(message)
                .then(() => showSnackBar())
                .catch((error) => console.error('Error copying link:', error));
        } else {
            console.log('Web Share API and Clipboard API not supported.');
        }
    };
    
    const showSnackBar = () => {
        setOpen(true);

        setTimeout(() => {
            setOpen(false);
        }, 5000);
    }
    return (
        <Box>
            <ShareIcon
                variant="contained"
                onClick={handleSendLink}
                id="share-game-via-text-button"
                sx={{ color: 'secondary.main', m: 2 }}
            />
            <Snackbar
                open={open}
                autoHideDuration={3000}
            >
                <Alert
                    variant='filled'
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    Link copied! Share now with friends :)
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default ShareButton;
