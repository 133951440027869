// conditional logic to show creative mode components and flow. at a higher level have truehome
import React, { useContext } from 'react'
import { Box, Button } from "@mui/material";
import { GameContext } from '../components/GameContext'
import CustomizeGameCard from '../components/app_bar/CustomizeGameCard';
import RuleCard from '../components/app_bar/RuleCard';
import { useNavigate } from 'react-router-dom';
import { updateSimpleModeContent } from '../components/utils/simpleGameUtils';
// add useUpdateSimpleModeGameCardState

function PersonalizePage() {
    const {
        userProfile,
        setCurrentCardDescription,
        setCurrentCardIndex,
        updateUserProfile,
        currentCardNumber,
        setCurrentCardNumber,
        setIsLastCardShowing,
        setNextButtonDisabled,
        setLikeClicked,
        setDislikeClicked,
        isGameActive,
        setIsGameActive,
        explicitCounter,
        setExplicitCounter,
    } = useContext(GameContext);
    const navigate = useNavigate();

    const startSimpleModeButtonClick = () => {
        updateSimpleModeContent(
            userProfile,
            setCurrentCardDescription,
            setCurrentCardIndex,
            updateUserProfile,
            currentCardNumber,
            setCurrentCardNumber,
            setIsLastCardShowing,
            setNextButtonDisabled,
            setLikeClicked,
            setDislikeClicked,
            isGameActive,
            setIsGameActive,
            explicitCounter,
            setExplicitCounter,
        )
        navigate(`/simple-mode/play`);
    };

    return (
        <Box>
            <CustomizeGameCard />
            <RuleCard />
            <br />
            <br />
            <br />
            <br />
            <Box sx={{ position: 'fixed', bottom: '1rem', left: 0, right: 0, zIndex: 1, display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    color="tertiary"
                    onClick={startSimpleModeButtonClick}
                    size="large"
                    sx={{ borderRadius: '50px', pl: 6, pr: 6, pt: 2, pb: 2, color: 'white', fontWeight: 'bold' }}
                >
                    Start Game
                </Button>
            </Box>
        </Box>
    )
}

export default PersonalizePage