const simpleSteps = {
    one: "Pick two players to pair up.",
    two: "Read the virtual card out loud.",
    three: "Both players close their eyes.",
    four: "If the player agrees individually with the card, they drink.",
    five: "If both players drink or refrain, the group says 'double trouble,' and both players drink.",
    six: "Repeat with the next pair of players and a new virtual card!"
};

const creativeSteps = {
    one: "Read the virtual card out loud.",
    two: "The selected players close their eyes.",
    three: "When a selected player agrees with the card, they drink.",
    four: "If both selected players drink or refrain, the group says 'double trouble,' and both players drink.",
    five: "The selected player guesses who wrote the card.",
    six: "Repeat until all cards are played."
};

const legal = {
    notice: `Please read these terms and conditions carefully before accessing or using this website or playing the game. By accessing or using this website or playing the game, you agree to be bound by these terms and conditions.`,
    ageRestriction: `Age Restriction: This game is intended for individuals aged 21 years or older. By playing this game, you confirm that you are 21 years of age or older.`,
    alcoholConsumption: `Discretionary Alcohol Consumption: This game may include references to or depictions of alcoholic beverages. However, the consumption of alcohol while playing this game is entirely at your discretion. We do not promote or endorse excessive or irresponsible drinking. Please drink responsibly and in accordance with the laws and regulations of your jurisdiction.`,
    nonDrinkingGameplay: `Non-Drinking Gameplay: This game can be enjoyed without the consumption of alcohol. If you choose not to consume alcohol while playing, you can still fully participate and have an enjoyable experience.`,
    liabilityDisclaimer: `Liability Disclaimer: By playing this game, you acknowledge that any decision to consume alcohol is solely your responsibility. We assume no liability for any actions, consequences, or damages resulting from your use of this website, playing the game, or the consumption of alcohol.`,
    intellectualProperty: `Intellectual Property: All intellectual property rights, including but not limited to copyrights, trademarks, and trade names, associated with this website and the game, are the property of their respective owners. Unauthorized use or reproduction of any content from this website or the game may violate intellectual property laws.`,
    modifications: `Modifications: We reserve the right to modify or update these terms and conditions at any time without prior notice. It is your responsibility to review the terms periodically for any changes. Your continued use of the website or playing the game after the modifications constitutes your acceptance of the revised terms.`,
    effectiveDate: `Effective Date: July 13, 2023`,
    lastUpdated: `Last Updated: July 13, 2023`,
};

// Winner
const winner = {
    id: '1234',
    name: 'Kia',
    emoji: '🥇',
};

// Users array
const users = [
    {
        id: '1234',
        name: 'Kia',
        emoji: '🥇',
        place: 1,
        points: 100,
    },
    {
        id: '1235',
        name: 'Ash',
        emoji: '🥇',
        place: 2,
        points: 90,
    },
    {
        id: '1236',
        name: 'Sam',
        emoji: '🥉',
        place: 3,
        points: 80,
    },
    {
        id: '1237',
        name: 'John',
        emoji: '😀',
        place: 4,
        points: 70,
    },
];

export {
    simpleSteps,
    creativeSteps,
    legal,
    winner,
    users
};
