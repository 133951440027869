import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HomeButton = ({ toggleDrawer }) => {
    const navigate = useNavigate();
    const handleNavHome = () => {
        toggleDrawer();
        navigate("/");
    }

    return (
        <Box p={2}>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleNavHome}
                sx={{
                    borderRadius: '10px',
                    // fontWeight: 'bold',
                    color: 'secondary.main',
                    height: '60px',
                    fontSize: '24px',
                        }}
            >
                Play
            </Button>
        </Box>
    );
}

export default HomeButton;
