import React, { useContext } from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CustomTooltip from '../info/CustomTooltip';
import { GameContext } from '../GameContext';
import { Launch } from '@mui/icons-material';
import * as amplitude from '@amplitude/analytics-browser';

const FormCard = () => {
    const { setFormCardVisibility, updateUserProfile } = useContext(GameContext);
    const handleButtonClick = () => {
        amplitude.track('Form Card Clicked');
        setFormCardVisibility(false);
        updateUserProfile({ hasClickedOnExternalSurvey: true });
        // Handle button click to redirect to the Google form link
        // Replace 'google-form-link' with your actual Google form link
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSd0LyQdER5r6akKgRl6YDo4b9DA0Sf6lJw4yclBA7GVzo54fw/viewform?usp=pp_url&entry.1189133563=Yes', '_blank');
    };

    return (
        <Box bgcolor="quaternary.main" borderRadius="10px" sx={{ m: 2, p: 2, position: 'relative', opacity: 0.80 }}>
            <Typography variant='body1' sx={{ textAlign: 'center' }}>
             Share to get it free forever
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
                <Button
                    variant="contained"
                    onClick={handleButtonClick}
                    startIcon={<Launch />}
                    sx={{
                        color: 'secondary.main',
                        p: 2,
                        pl: 3,
                        pr: 3,
                        fontWeight: 'bold',
                        borderRadius: '10px',
                        fontSize: 16,
                        minWidth: '250px',
                    }}>
                    share feedback
                </Button>
            </Box>
            <CustomTooltip
                title="This game is currently free while we make it better for you and your friends, but by filling out the external form in full and sending the game link to at least two friends, you'll secure free access to the basic version forever. Just click below, fill out the form, and share the link!"
            >
                <Stack
                    direction={'row'}
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography fontSize="12px">What does this mean?</Typography>
                    <HelpIcon fontSize='small'/>
                </Stack>
            </CustomTooltip>
        </Box>
    );
};

export default FormCard;
