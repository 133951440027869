import React from 'react';
import { Button, Stack } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import { selectPrompt, getThinkersNames, updatePlayerScore, getNumOfCards, getPrevThinkersNames } from '../utils/gameUtils';
import * as amplitude from '@amplitude/analytics-browser';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UpdateScore = ({ setCurrentCardType }) => {
  const navigate = useNavigate();
  const { urlGameCode } = useParams();

  const handleUpdateScore = (points) => {
    updatePlayerScore(points, urlGameCode);
    const numOfCards = getNumOfCards(urlGameCode);
    const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
    const storedCurrentCardNum = storedLocalData && storedLocalData.currentCardNumber;

    if (storedCurrentCardNum >= numOfCards) {
      amplitude.track('Finish Creative Mode');
      const newUrl = `/creative-mode/${urlGameCode}/results`;
      navigate(newUrl, { replace: true });
    } else {
      updateCardType('prompt');
      const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
      if (storedLocalData) {
        storedLocalData.currentPrompt = selectPrompt(urlGameCode);
        storedLocalData.currentPlayerNames = getThinkersNames(urlGameCode);
        storedLocalData.currentGuesserIndex += 1;
        storedLocalData.currentCardNumber += 1;
        localStorage.setItem(urlGameCode + '_data', JSON.stringify(storedLocalData));
      }
    }
  }


  const handleNavBack = () => {
    updateCardType('prompt');
    const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));

    if (storedLocalData) {
      storedLocalData.currentPlayerNames = getPrevThinkersNames(urlGameCode);
      storedLocalData.currentPlayerIndex -= 1;
      storedLocalData.currentCardNumber -= 1;
      localStorage.setItem(urlGameCode + '_data', JSON.stringify(storedLocalData));
    }
  }

  const updateCardType = (cardType) => {
    const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
    if (storedLocalData) {
      storedLocalData.currentCardType = cardType;
      localStorage.setItem(urlGameCode + '_data', JSON.stringify(storedLocalData));
    }
    const type = storedLocalData && storedLocalData.currentCardType;
    setCurrentCardType(type);
  }

  return (

    <Stack
      direction="row"
      width={'100vw'}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        p: 2,
        boxSizing: 'border-box',
      }}
    >
      <Button
        onClick={handleNavBack}
        variant="outlined"
        sx={{
          borderRadius: '10px',
          fontWeight: 'bold',
          color: 'primary.main',
          mr: 1,
          fontSize: '1.5rem',
          border: '2px solid',
          borderColor: 'primary.main'
        }}
      >
        <ArrowBackIcon sx={{ fontSize: '2rem' }} />
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={() => handleUpdateScore(-25)}
        sx={{
          borderRadius: '10px',
          fontWeight: 'bold',
          color: 'secondary.main',
          width: '100%',
          mr: 1,
          ml: 1,
          fontSize: '1.5rem'
        }}
      >
        - 25
      </Button>

      <Button
        variant="contained"
        color="success"
        onClick={() => handleUpdateScore(100)}
        sx={{
          borderRadius: '10px',
          fontWeight: 'bold',
          color: 'secondary.main',
          width: '100%',
          ml: 1,
          fontSize: '1.5rem'
        }}
      >
        + 100
      </Button>
    </Stack>
  );
};

export default UpdateScore;