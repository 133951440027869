import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { GameContext } from '../GameContext'; // Assuming the context is imported from a separate file

const ExplicitToggle = () => {
  const { userProfile, updateUserProfile } = useContext(GameContext);

  const handleToggleExplicitContent = () => {
    const newExplicitContent = !userProfile.includeExplicitContent;
    updateUserProfile({ includeExplicitContent: newExplicitContent });
  };

  const buttonStyle = {
    bgcolor: userProfile.includeExplicitContent ? 'black' : 'secondary.main',
    color: userProfile.includeExplicitContent ? 'secondary.main' : 'black',
    '&:hover': {
      bgcolor: userProfile.includeExplicitContent ? 'black' : 'secondary.main',
      color: userProfile.includeExplicitContent ? 'secondary.main' : 'black',
    },
  };

  return (
    <Box mb={1}>
      <Button
        variant="contained"
        fullWidth
        onClick={handleToggleExplicitContent}
        sx={buttonStyle}
      >
        {userProfile.includeExplicitContent ? 'Explicit Content Added' : 'Add Explicit Content'}
      </Button>
    </Box>
  );
};

export default ExplicitToggle;
