import React, { useState } from 'react';
import { Box, Grid, Typography, Button, TextField, Stack } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import CustomTooltip from '../info/CustomTooltip';
import { useNavigate } from 'react-router-dom';
import { checkIfGameCodeExists } from './GameCodeUtils';
import { addPlayerData } from '../utils/dbUtils';


const JoinCard = () => {
  const [userInputtedGameCode, setUserInputtedGameCode] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleJoinClick = () => {

    let code = userInputtedGameCode;

    const storedLocalDataString = localStorage.getItem(code + '_data');
    const storedLocalData = storedLocalDataString ? JSON.parse(storedLocalDataString) : {};
    const playerAlreadyExists = storedLocalData && storedLocalData.playerId;

    checkIfGameCodeExists(code).then(async result => {
      const player = {
        name: "",
        isAddingPrompts: true,
        isReady: false,
        isHost: false,
        prompts: [],
      };

      if (result.expiredCode) {
        // expired game code
        setErrorMessage('Game code has expired');
        setError(true);
        resetError();
      } else if (result.exists && playerAlreadyExists) {
        // Host or player is rejoining
        console.log('Host or player is rejoining');
        navigate(`/creative-mode/${code}/enter-prompts`);
      } else if (result.exists) {
        // new player is joining
        console.log('new player is joining');
        const playerId = await addPlayerData(code, player);

        const data = {
          gameId: code,
          isHost: false,
          playerId: playerId,
          creationTimestamp: new Date().toISOString(),
        };

        localStorage.setItem(code + '_data', JSON.stringify(data));

        navigate(`/creative-mode/${code}/enter-prompts`);
      } else {
        // game code does not exist
        setErrorMessage('Game code does not exist');
        setError(true);
        resetError();
      }

    })

    const resetError = () => {
      setTimeout(() => {
        setError(false);
        setErrorMessage('');
      }, 4000);
    }

  }
  return (
    <Box
      borderRadius="10px"
      sx={{
        m: 2,
        p: 2,
        alignItems: 'center',
        bgcolor: 'quaternary.main',
        opacity: 0.8,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="body1" align='center'>
            <Typography component="span" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              Join&nbsp;
            </Typography>
            a game |&nbsp;
            <Typography component="span" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              Enter&nbsp;
            </Typography>
            your game code
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth
            sx={{ borderRadius: '4px' }}
            variant='outlined'
            label="Enter Game Code"
            placeholder='0000'
            value={userInputtedGameCode}
            onChange={(e) => setUserInputtedGameCode(e.target.value)}
            error={error}
            helperText={errorMessage}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleJoinClick}
              disabled={userInputtedGameCode.length < 4}
              sx={{
                borderRadius: '10px',
                fontWeight: 'bold',
                color: 'secondary.main',
              }}
            >
              Join
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CustomTooltip
            title="Don't have a 4-digit game code? No problem! You can start your own creative mode game and become the host. Game codes are only required for creative mode play."
            duration={10000}
          >
            <Stack
              direction={'row'}
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Typography fontSize="12px">What does this mean?</Typography>
              <HelpIcon fontSize="small" />
            </Stack>
          </CustomTooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JoinCard;
