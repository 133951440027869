import React, { useContext } from 'react';
import { GameContext } from '../GameContext';
import { Stack, Button } from '@mui/material';
import { getGuessersName, getPrevGuessersName, selectPrevPrompt, undoLastScoreUpdate } from '../utils/gameUtils';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { updateSimpleModeContent } from '../utils/simpleGameUtils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const NavButtons = ({ setCurrentCardType }) => {
  const {
    isNextButtonDisabled,
    setCurrentCardIndex,
    setCurrentCardDescription,
    setCurrentCardNumber,
    currentCardNumber,
    setNextButtonDisabled,
    setLikeClicked,
    setDislikeClicked,
    userProfile,
    updateUserProfile,
    isGameActive,
    setIsGameActive,
    setIsLastCardShowing,
    explicitCounter,
    setExplicitCounter,
  } = useContext(GameContext);
  const location = useLocation();
  const { urlGameCode } = useParams();
  const navigate = useNavigate();
  const isSimpleModeGamePage = location.pathname.includes("/simple-mode/play");
  const isCreativeModeGamePage = location.pathname.startsWith(`/creative-mode/${urlGameCode}/play`);


  const handleNextClick = () => {
    if (isSimpleModeGamePage) {
      updateSimpleModeContent(
        userProfile,
        setCurrentCardDescription,
        setCurrentCardIndex,
        updateUserProfile,
        currentCardNumber,
        setCurrentCardNumber,
        setIsLastCardShowing,
        setNextButtonDisabled,
        setLikeClicked,
        setDislikeClicked,
        isGameActive,
        setIsGameActive,
        explicitCounter,
        setExplicitCounter,
      );
    } else {
      updateCardType('guess');
      const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));

      if (storedLocalData) {
        storedLocalData.currentPlayerNames = [getGuessersName(urlGameCode)];
        storedLocalData.currentPlayerIndex += 1;
        storedLocalData.currentCardNumber += 1;
        localStorage.setItem(urlGameCode + '_data', JSON.stringify(storedLocalData));
      }

      setCurrentCardNumber(prevCount => prevCount + 1);
    }

  }

  const handleNavBack = () => {

    const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
    const storedCurrentCardNumber = storedLocalData && storedLocalData.currentCardNumber;

    if (storedCurrentCardNumber <= 1) {
      navigate(`/creative-mode/${urlGameCode}/waiting-lobby`);
    } else {
      updateCardType('guess');
      const prevGuesser = getPrevGuessersName(urlGameCode);
      const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));

      if (storedLocalData) {
        storedLocalData.currentPlayerNames = [prevGuesser];
        storedLocalData.currentPrompt = selectPrevPrompt(urlGameCode);
        storedLocalData.currentGuesserIndex -= 1;
        storedLocalData.currentCardNumber -= 1;
        localStorage.setItem(urlGameCode + '_data', JSON.stringify(storedLocalData));
      }

      undoLastScoreUpdate(urlGameCode, prevGuesser);
    }
  }

  const updateCardType = (cardType) => {
    const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));

    if (storedLocalData) {
      storedLocalData.currentCardType = cardType;
      localStorage.setItem(urlGameCode + '_data', JSON.stringify(storedLocalData));
    }

    const type = storedLocalData && storedLocalData.currentCardType;
    setCurrentCardType(type);
  }

  return (
    <Stack
      direction="row"
      width={'100vw'}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        p: 2,
        boxSizing: 'border-box',
      }}
    >
      {isCreativeModeGamePage &&
        <Button
          onClick={handleNavBack}
          variant="outlined"
          sx={{
            borderRadius: '10px',
            fontWeight: 'bold',
            color: 'primary.main',
            mr: 2,
            fontSize: '1.5rem',
            border: '2px solid',
            borderColor: 'primary.main'
          }}
        >
          <ArrowBackIcon sx={{ fontSize: '2rem' }} />
        </Button>
      }
      {!isNextButtonDisabled &&
        <Button
          onClick={handleNextClick}
          variant="contained"
          disabled={isNextButtonDisabled}
          sx={{
            borderRadius: '10px',
            fontWeight: 'bold',
            color: 'secondary.main',
            width: '100%',
            fontSize: '1.5rem'
          }}
        >
          <Stack direction="row" alignItems="center">
            Next
            <ArrowForwardIcon sx={{ pl: 1, fontSize: '2rem' }} />
          </Stack>
        </Button>
      }
    </Stack>
  );
};

export default NavButtons;