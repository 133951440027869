import React from 'react';
import {
    Button,
    Drawer,
    Box,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function CreativityBank() {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                onClick={handleClickOpen}
                sx={{
                    borderRadius: '10px',
                    fontWeight: 'bold',
                    color: 'primary.main',
                }}>
                Not feeling creative?
            </Button>

            <Drawer
                anchor='bottom'
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        height: '90vh',
                        borderRadius: '20px 20px 0 0'

                    },
                }}
            >
                <Stack direction="row" justifyContent="center" alignItems="center" p={2}>
                    <Typography variant="h6" fontWeight="bold" sx={{ color: 'primary.main' }}>
                        Creativity Bank
                    </Typography>

                    <Box position="absolute" right={12}>
                        <CloseRoundedIcon
                            onClick={handleClose}
                            color="primary"
                        />
                    </Box>
                </Stack>

                <List sx={{ bgcolor: 'quaternary.main' }}>
                    <ListItem>
                        <ListItemText>
                            <Typography variant='body1'>
                                Make a "Drink If You Think..." game card tailored to the group.
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </List>
                <List sx={{ bgcolor: 'primary.main', color: 'secondary.main' }}>
                    <ListItem>
                        <ListItemText>
                            <Typography variant='h6' fontWeight='bold'>
                                Use these category ideas:
                            </Typography>
                            <ul>
                                <li>Inside jokes</li>
                                <li>Embarrassing or spicy moments</li>
                                <li>Random curiosities</li>
                            </ul>
                        </ListItemText>
                    </ListItem>
                </List>
                <List sx={{ bgcolor: 'quaternary.main' }}>
                    <ListItem>
                        <ListItemText>
                            <Typography variant='h6' fontWeight='bold'>
                                Try prompts like:
                            </Typography>
                                <ul>
                                    <li>Drink if you think you remember the first time you and your partner meet?</li>
                                    <li>Drink if you think your partner has ever been injured during sex</li>
                                    <li>Drink if you think your partner reminds you of someone you know</li>
                                </ul>
                        </ListItemText>
                    </ListItem>
                </List>
                <List sx={{ bgcolor: 'primary.main', color: 'secondary.main' }}>
                    <ListItem>
                        <ListItemText>
                            <Typography variant='h6' fontWeight='bold'>
                                Fill in the blanks:
                            </Typography>
                            <ul>
                            <li>Drink if you think you are better at _____ than your partner</li>
                            <li>Drink if you have ever _____</li>
                            <li>Drink if you think your partner has ever _____</li>
                            <li>Drink if you think you know your partner's _____</li>
                            <li>Drink if you think your partner will _____</li>
                            </ul>                     
                        </ListItemText>
                    </ListItem>
                </List >
                <List sx={{ bgcolor: 'quaternary.main' }}>
                <ListItem>
                    <ListItemText>
                        <Typography variant='body1'>
                            Keep it lighthearted, funny, and conversational to spark storytelling. Focus on inside jokes, funny memories, and quirky habits unique to the group.
                        </Typography>
                    </ListItemText>
                </ListItem>
                </List>
            </Drawer>
        </>
    );
}