import React from 'react'
import WaitingLobbyCard from '../components/pre_game/WaitingLobbyCard';
import RuleCard from '../components/app_bar/RuleCard';
import { Box } from '@mui/material';


function WaitingLobbyPage() {
    return (
        <Box>
            <WaitingLobbyCard />
            <RuleCard />
        </Box>
    )
}

export default WaitingLobbyPage
