import React, { useContext } from 'react';
import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import { GameContext } from '../GameContext';
import ShareCodeButton from '../share/ShareCodeButton';
import HelpIcon from '@mui/icons-material/Help';
import CustomTooltip from '../info/CustomTooltip';
import QRCodeButton from '../share/QRCodeButton';
import QRCode from 'qrcode.react';

const ShareCodeCard = () => {
    const { gameCode } = useContext(GameContext);
    const navigate = useNavigate();
    const { urlGameCode } = useParams();

    const handleNextClick = () => {
        navigate(`/creative-mode/${gameCode}/enter-prompts`);
    }

    return (
        <Box
            borderRadius="10px"
            sx={{
                m: 2,
                p: 2,
                alignItems: 'center',
                bgcolor: 'quaternary.main',
                opacity: 0.8,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="body1" align='center'>
                        <Typography component="span" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            Click to share&nbsp;
                        </Typography>
                        the code below with at least&nbsp;
                        <Typography component="span" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            3 other friends
                        </Typography>
                    </Typography>
                </Grid>
                <Grid container item xs={12} direction={'column'} alignItems="center" justifyContent="center">
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                        <ShareCodeButton
                            code={urlGameCode}
                            iconProps={{
                                color: 'primary',
                                sx: { fontSize: 80 }
                            }}
                        />
                        <QRCodeButton
                            code={urlGameCode}
                            buttonIcon={
                                <QRCode value={`${window.location.origin}/creative-mode/${urlGameCode}/enter-prompts`} size={70} />
                            }
                        />
                    </Stack>
                    <Typography variant="body1" color='black' textAlign='center' m={2} fontWeight={'bold'}>
                        Code: {gameCode}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNextClick}
                            sx={{
                                borderRadius: '10px',
                                fontWeight: 'bold',
                                color: 'secondary.main',
                            }}
                        >
                            Next
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <CustomTooltip
                        title="You are the host. Share the game code link with others. You will need your phone during the game, but everyone else will not need it once they submit their prompts via the link."
                        duration={12000}
                    >
                        <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography fontSize="12px">Need more helpful info?</Typography>
                            <HelpIcon fontSize='small' />
                        </Stack>
                    </CustomTooltip>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ShareCodeCard;
