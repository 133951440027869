import React, { useContext } from 'react';
import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import CustomTooltip from '../info/CustomTooltip';
import { useNavigate } from 'react-router-dom';
import { GameContext } from '../GameContext';
import { generateUniqueGameCode } from './GameCodeUtils';
import { addPlayerData } from '../utils/dbUtils';
import * as amplitude from '@amplitude/analytics-browser';

const GameModeCard = () => {
    const {
        setGameCode,
        setPlayerId,
        setCurrentGameMode,
        playAgainResetContext,
    } = useContext(GameContext);
    const navigate = useNavigate();

    const handleCreativeClick = async () => {

        amplitude.track('Start Pre Creative Mode');

        playAgainResetContext();

        const code = await generateUniqueGameCode();

        const player = {
            name: "",
            isAddingPrompts: true,
            isReady: false,
            isHost: true,
            prompts: [],
        };

        const playerId = await addPlayerData(code, player);
        setPlayerId(playerId);
        setGameCode(code);
        const data = {
            gameId: code,
            isHost: true,
            playerId: playerId,
            creationTimestamp: new Date().toISOString(),
        };
        localStorage.setItem(code + '_data', JSON.stringify(data));
        navigate(`/creative-mode/${code}/share`);

    }

    const handleSimpleClick = () => {
        amplitude.track('Start Simple Mode');
        setCurrentGameMode('simple');
        playAgainResetContext();
        navigate(`/simple-mode/personalize`);
    }

    return (
        <Box
            borderRadius="10px"
            sx={{
                m: 2,
                p: 2,
                alignItems: 'center',
                bgcolor: 'quaternary.main',
                opacity: 0.8,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="body1" align='center'>
                        <Typography component="span" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            Start&nbsp;
                        </Typography>
                        a game | Select a&nbsp;
                        <Typography component="span" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            game mode
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleCreativeClick}
                        sx={{
                            borderRadius: '10px',
                            fontWeight: 'bold',
                            color: 'secondary.main',
                            height: '60px',
                            fontSize: '24px',
                        }}
                    >
                        Creative
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <CustomTooltip
                        title="For creative groups who want to write their own prompts, answer the prompts, guess who wrote each prompt, and win points to crown the winner."
                        duration={10000}
                    >
                        <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography fontSize="12px">What does this mean?</Typography>
                            <HelpIcon fontSize='small' />
                        </Stack>
                    </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSimpleClick}
                        sx={{
                            borderRadius: '10px',
                            fontWeight: 'bold',
                            color: 'secondary.main',
                            height: '60px',
                            fontSize: '24px',
                        }}
                    >
                        Simple
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <CustomTooltip
                        title="For groups who want to get started without having to write their own prompts."
                    >
                        <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography fontSize="12px">What does this mean?</Typography>
                            <HelpIcon fontSize='small' />
                        </Stack>
                    </CustomTooltip>
                </Grid>
            </Grid>
        </Box>
    );
};

export default GameModeCard;
