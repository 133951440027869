
import React, { useEffect, useContext } from 'react';
import { GameContext } from './GameContext';
import * as amplitude from '@amplitude/analytics-browser';

// BUG: if user exsits the page before changing visabilty the user profile is not updated; however, if the user changes visablity then closes the page the close data is updated

const UpdateDatabase = () => {
  const { userProfile } = useContext(GameContext);
  // amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, { defaultTracking: { sessions: true, }, });


  useEffect(() => {
    const eventProperties = userProfile;
    amplitude.track('Update User Profile', eventProperties);

  }, [userProfile]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        console.log('The user is no longer viewing the page.');
        updateUserProfileInDB();
      }
    };

    const updateUserProfileInDB = async () => {
      const eventProperties = userProfile;
      amplitude.track('Update User Profile', eventProperties);
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // remove event listener when component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  });

  return (
    // Your component JSX
    <div>
      {/* ... */}
    </div>
  );
};

export default UpdateDatabase;