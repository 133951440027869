import React from 'react';
import { Typography, Box } from '@mui/material';
import PodiumPlayers from './PodiumPlayers';
import ResultsTable from './ResultsTable';
import { getFirstPlaceFinishMessage } from '../../utils/gameUtils';
import { useParams } from 'react-router-dom';


const ResultsCard = () => {
    const { urlGameCode } = useParams();
    const storedLocalData = JSON.parse(localStorage.getItem(urlGameCode + '_data'));
    const storedReadyPlayers = storedLocalData && storedLocalData.readyPlayers ? storedLocalData.readyPlayers : 0; 

    const message = getFirstPlaceFinishMessage(urlGameCode);

    return (
        <Box borderRadius="10px" sx={{ m: 2, p: 2, bgcolor: 'quaternary.main', opacity: 0.8 }}>
            <Box textAlign="center" mb={2}>
                <Typography variant="h4" fontWeight={'bold'}>
                    {message}
                </Typography>
            </Box>
            {storedReadyPlayers.length > 0 && <PodiumPlayers />}
            <ResultsTable />
        </Box>
    );
};

export default ResultsCard;
