import React, { useState } from 'react';
import { Box, Typography, Popover, Button } from '@mui/material';
import { legal } from '../../data';
import CloseIcon from '@mui/icons-material/Close';

const LegalFooter = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleTermsClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Typography variant="body2" align="center" color="grey">
        &copy; {new Date().getFullYear()} JustRecess.com. All rights reserved.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="text" color="inherit" onClick={handleTermsClick}>
          Terms
        </Button>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Box>
          <Typography variant="body2" p={1} fontWeight="bold">
            {legal.notice}
          </Typography>
          <Typography variant="body2" p={1}>
            {legal.ageRestriction}
          </Typography>
          <Typography variant="body2" p={1}>
            {legal.alcoholConsumption}
          </Typography>
          <Typography variant="body2" p={1}>
            {legal.nonDrinkingGameplay}
          </Typography>
          <Typography variant="body2" p={1}>
            {legal.liabilityDisclaimer}
          </Typography>
          <Typography variant="body2" p={1}>
            {legal.intellectualProperty}
          </Typography>
          <Typography variant="body2" p={1}>
            {legal.modifications}
          </Typography>
          <Typography variant="body2" p={1}>
            {legal.effectiveDate}
          </Typography>
          <Typography variant="body2" p={1}>
            {legal.lastUpdated}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default LegalFooter;
