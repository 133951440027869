import React from 'react'
import { Box } from '@mui/material';
import EnterPromptsCard from '../components/pre_game/EnterPromptsCard';
import PlayerStatus from '../components/pre_game/PlayerStatus';



function EnterPromptsPage() {
    return (
        <Box>
            <EnterPromptsCard />
            <PlayerStatus />
        </Box>
    )
}

export default EnterPromptsPage
